import React, { useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  TextField,
  Select,
  MenuItem,
  Button,
  Paper,
  Grid,
  ListSubheader,
  FormControl,
  FormHelperText,
  FormLabel,
  Card,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  CircularProgress,
} from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import Dropzone from "react-dropzone";
import { InsertDriveFile } from "@material-ui/icons";
import isMobilePhone from "validator/lib/isMobilePhone";
import { Auth, API, Storage } from "aws-amplify";
import { Candidate } from "../../CandidateTypes";
import { MyAuth } from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth/lib/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGoogle,
  faFacebookSquare,
  faApple,
} from "@fortawesome/free-brands-svg-icons";
import MuiPhoneNumber from "material-ui-phone-number";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    signUpPage: {
      [theme.breakpoints.up("sm")]: {
        display: "flex",
        justifyContent: "center",
        marginTop: 32,
        marginBottom: 32,
      },
      [theme.breakpoints.down("xs")]: {
        padding: "24px 0px",
      },
    },
    submit: {
      marginTop: theme.spacing(3),
      width: 100,
    },
    input: {
      width: "100%",
    },
    dropzone: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingTop: 75,
      paddingBottom: 75,
      paddingLeft: 20,
      paddingRight: 20,
      borderWidth: 2,
      borderRadius: 2,
      borderColor: "#bdbdbd",
      borderStyle: "dashed",
      backgroundColor: "#eeeeee",
      color: "#757575",
      outline: "none",
      transition: "border .24s ease-in-out",
    },
    dropzoneError: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingTop: 97,
      paddingBottom: 97,
      paddingLeft: 20,
      paddingRight: 20,
      borderWidth: 2,
      borderRadius: 2,
      borderColor: "red",
      borderStyle: "dashed",
      backgroundColor: "#eeeeee",
      color: "#757575",
      outline: "none",
      transition: "border .24s ease-in-out",
    },
    label: {
      marginBottom: 6,
    },
    error: {
      "&.MuiFormHelperText-contained": {
        marginLeft: 0,
      },
    },
    complete: {
      display: "flex",
      justifyContent: "center",
      marginTop: 64,
    },
    form: {
      margin: "0 auto",
      [theme.breakpoints.up("md")]: {
        width: 600,
      },
    },
    applyButton: {
      marginLeft: 8,
      backgroundColor: "#259a49",
      color: "#ffffff",
      "&:hover": {
        backgroundColor: "#006b1e",
      },
    },
    actions: {
      "&.MuiCardActions-spacing > :not(:first-child)": {
        marginLeft: 0,
      },
    },
    googleButton: {
      backgroundColor: "#dd4b39",
      "&:hover": {
        backgroundColor: "#ff7d64",
      },
      marginBottom: 16,
      justifyContent: "flex-start",
      [theme.breakpoints.up("sm")]: {
        width: "300px",
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    facebookButton: {
      backgroundColor: "#3b5998",
      "&:hover": {
        backgroundColor: "#6d85c9",
      },
      marginBottom: 16,
      justifyContent: "flex-start",
      [theme.breakpoints.up("sm")]: {
        width: "300px",
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    appleButton: {
      backgroundColor: "#000000",
      "&:hover": {
        backgroundColor: "#424242",
      },
      justifyContent: "flex-start",
      [theme.breakpoints.up("sm")]: {
        width: "300px",
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    socialButtonDiv: {
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
  })
);

type Inputs = {
  title: string;
  firstName: string;
  surname: string;
  phone: string;
  email: string;
  industry: string;
  industryOther: string;
  location: string;
  uploadCv: string;
  cvFile: string;
  password: string;
  confirmPassword: string;
};

interface Props {
  auth: MyAuth;
}

export default function SignUp({ auth }: Props) {
  const classes = useStyles();

  const [registrationComplete, setRegistrationComplete] =
    useState<boolean>(false);
  const [uploadedCvFile, setUploadedCvFile] = useState<Array<File>>([]);

  const [registerLoading, setRegisterLoading] = useState<boolean>(false);

  const [confirmSignUp, setConfirmSignUp] = useState<boolean>(false);
  const [data, setData] = useState<Inputs>();
  const [error, setSignUpError] = useState<string>();
  const [cognitoUser, setCognitoUser] = useState<any>();

  const navigate = useNavigate();

  const {
    handleSubmit,
    watch,
    control,
    errors,
    setValue,
    setError,
    clearErrors,
  } = useForm<Inputs>({
    defaultValues: {
      title: "",
      firstName: "",
      surname: "",
      phone: "",
      email: "",
      industry: "",
      industryOther: "",
      location: "",
      cvFile: "",
      password: "",
      confirmPassword: "",
    },
  });

  const watchIndustry = watch("industry");

  const onSubmit = (data: Inputs) => {
    data.phone = data.phone.replace(/^(\+6[14]) 0/, "$1");
    data.phone = data.phone.replace(/[- ]/g, "");
    if (isMobilePhone(data.phone, ["en-AU", "en-NZ"])) {
      if (data.password === data.confirmPassword) {
        addUser(data);
      } else {
        setError("confirmPassword", { message: "Password must match" });
      }
    } else {
      setError("phone", { message: "Invalid number" });
    }
  };

  function addUser(data: Inputs) {
    //console.log(data)
    setSignUpError(undefined);
    setRegisterLoading(true);

    setData(data);

    let params = {
      username: data.email,
      password: data.password,
      email: data.email,
      given_name: data.firstName,
      family_name: data.surname,
      phone_number: data.phone,
    };

    auth
      .signUp(params)
      .then((response) => {
        console.log(response);
        if (response) {
          setCognitoUser(response);
          // createCandidate(response.userSub, data)
          setRegisterLoading(false);
          setRegistrationComplete(true);
          setConfirmSignUp(true);
          // setRegisteredCandidate(response)
        }
      })
      .catch((error: any) => {
        console.log("Error signing up", error);
        if (error.name === "UsernameExistsException") {
          setSignUpError(
            "Error: An account with the given email already exists"
          );
        } else if (error.name === "UserLambdaValidationException") {
          setSignUpError("Error: A user with the same email address exists");
        } else {
          setError("password", { message: error.message });
        }
        setRegisterLoading(false);
      });
  }

  const handleDrop = (files: Array<File>) => {
    let timeInMills = new Date().getTime();
    var objKey = timeInMills + "_CV_" + files[0].name;

    Storage.put(objKey, files[0])
      .then(() => {
        Storage.get(objKey, { level: "public", download: false })
          .then(() => {
            //console.log("FILE UPLOAD", objKey)
            setUploadedCvFile(files);
            setValue("cvFile", objKey);
            clearErrors("cvFile");
          })
          .catch((error: any) => {
            console.log("ERROR fetching from s3", error);
          });
      })
      .catch((error: any) => {
        console.log("ERROR uploading to s3", error);
      });
  };

  const cvFileDropzoneClass = () => {
    if (errors.cvFile) {
      return classes.dropzoneError;
    } else {
      return classes.dropzone;
    }
  };

  function calcSize(fileSize: number): string {
    if (fileSize < 1000000) {
      return `${Math.round(fileSize / Math.pow(10, 3))} KB`;
    }
    return `${(fileSize / Math.pow(10, 6)).toFixed(1)} MB`;
  }

  const getFromRoute = (): string => {
    let route = localStorage.getItem("lowie-route-from");
    if (route) {
      // console.log(route)
      localStorage.removeItem("lowie-route-from");
      if (route === "/") return "/portal";
      return route;
    } else {
      return "/portal";
    }
  };

  return (
    <div style={{ position: "relative", padding: 8 }}>
      <div>
        {!registrationComplete ? (
          <div className={classes.signUpPage}>
            <div style={{ maxWidth: 700 }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: 32,
                }}
              >
                <img
                  src="images/lowie-logo-1.png"
                  alt="Lowie Recruitment"
                  width={300}
                />
              </div>

              <Card style={{ marginBottom: 32 }}>
                <div style={{ padding: 16 }}>
                  <div
                    style={{
                      marginBottom: 32,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <h3>Create a new account</h3>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: 32,
                    }}
                  >
                    <div className={classes.socialButtonDiv}>
                      <div>
                        <Button
                          className={classes.googleButton}
                          variant="contained"
                          color="primary"
                          onClick={() =>
                            auth.socialSignIn(
                              CognitoHostedUIIdentityProvider.Google,
                              getFromRoute()
                            )
                          }
                          startIcon={
                            <FontAwesomeIcon
                              icon={faGoogle}
                              style={{ marginRight: 16 }}
                            />
                          }
                        >
                          Continue with Google
                        </Button>
                      </div>
                      <div>
                        <Button
                          className={classes.facebookButton}
                          variant="contained"
                          color="primary"
                          onClick={() =>
                            auth.socialSignIn(
                              CognitoHostedUIIdentityProvider.Facebook,
                              getFromRoute()
                            )
                          }
                          startIcon={
                            <FontAwesomeIcon
                              icon={faFacebookSquare}
                              style={{ marginRight: 16 }}
                            />
                          }
                        >
                          Continue with Facebook
                        </Button>
                      </div>
                      <div>
                        <Button
                          className={classes.appleButton}
                          variant="contained"
                          color="primary"
                          onClick={() =>
                            auth.socialSignIn(
                              CognitoHostedUIIdentityProvider.Apple,
                              getFromRoute()
                            )
                          }
                          startIcon={
                            <FontAwesomeIcon
                              icon={faApple}
                              style={{ marginRight: 16 }}
                            />
                          }
                        >
                          Continue with Apple
                        </Button>
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: 32,
                      color: "grey",
                    }}
                  >
                    <div style={{ flexGrow: 1, marginRight: 16 }}>
                      <hr />
                    </div>
                    <h6>OR</h6>
                    <div style={{ flexGrow: 1, marginLeft: 16 }}>
                      <hr />
                    </div>
                  </div>

                  <div style={{ marginBottom: 32 }}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={2}>
                        <FormControl
                          error={Boolean(errors.title)}
                          className={classes.input}
                          size="small"
                        >
                          <FormLabel className={classes.label}>Title</FormLabel>

                          <Controller
                            as={
                              <Select displayEmpty={true} variant="filled">
                                <MenuItem value="Mr">Mr</MenuItem>
                                <MenuItem value="Miss">Miss</MenuItem>
                                <MenuItem value="Ms">Ms</MenuItem>
                                <MenuItem value="Mrs">Mrs</MenuItem>
                              </Select>
                            }
                            name="title"
                            control={control}
                            defaultValue=""
                            rules={{
                              required: "Required",
                            }}
                          />
                          <FormHelperText>
                            {errors.title && errors.title.message}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={5}>
                        <FormControl
                          className={classes.input}
                          error={Boolean(errors.firstName)}
                        >
                          <FormLabel className={classes.label}>
                            First Name
                          </FormLabel>

                          <Controller
                            as={
                              <TextField
                                placeholder="Enter first name"
                                fullWidth
                                variant="filled"
                                size="small"
                                error={errors.firstName ? true : false}
                                helperText={errors.firstName?.message}
                                FormHelperTextProps={{
                                  className: classes.error,
                                }}
                              />
                            }
                            name="firstName"
                            control={control}
                            defaultValue=""
                            rules={{
                              required: "Required",
                            }}
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={5}>
                        <FormControl
                          className={classes.input}
                          error={Boolean(errors.surname)}
                        >
                          <FormLabel className={classes.label}>
                            Surname
                          </FormLabel>

                          <Controller
                            as={
                              <TextField
                                placeholder="Enter surname name"
                                fullWidth
                                variant="filled"
                                size="small"
                                error={errors.surname ? true : false}
                                helperText={errors.surname?.message}
                                FormHelperTextProps={{
                                  className: classes.error,
                                }}
                              />
                            }
                            name="surname"
                            control={control}
                            defaultValue=""
                            rules={{
                              required: "Required",
                            }}
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={12}>
                        <FormControl
                          className={classes.input}
                          error={Boolean(errors.email)}
                        >
                          <FormLabel className={classes.label}>Email</FormLabel>

                          <Controller
                            as={
                              <TextField
                                placeholder="Enter email"
                                fullWidth
                                variant="filled"
                                size="small"
                                error={errors.email ? true : false}
                                helperText={errors.email?.message}
                                FormHelperTextProps={{
                                  className: classes.error,
                                }}
                              />
                            }
                            name="email"
                            control={control}
                            defaultValue=""
                            rules={{
                              required: "Required",
                            }}
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <FormControl
                          className={classes.input}
                          error={Boolean(errors.password)}
                        >
                          <FormLabel className={classes.label}>
                            Password
                          </FormLabel>

                          <Controller
                            as={
                              <TextField
                                placeholder="Enter password"
                                fullWidth
                                variant="filled"
                                size="small"
                                type="password"
                                error={errors.password ? true : false}
                                helperText={errors.password?.message}
                                FormHelperTextProps={{
                                  className: classes.error,
                                }}
                              />
                            }
                            name="password"
                            control={control}
                            defaultValue=""
                            rules={{
                              required: "Required",
                            }}
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <FormControl
                          className={classes.input}
                          error={Boolean(errors.confirmPassword)}
                        >
                          <FormLabel className={classes.label}>
                            Confirm Password
                          </FormLabel>

                          <Controller
                            as={
                              <TextField
                                placeholder="Confirm Password"
                                fullWidth
                                variant="filled"
                                size="small"
                                type="password"
                                error={errors.confirmPassword ? true : false}
                                helperText={errors.confirmPassword?.message}
                                FormHelperTextProps={{
                                  className: classes.error,
                                }}
                              />
                            }
                            name="confirmPassword"
                            control={control}
                            defaultValue=""
                            rules={{
                              required: "Required",
                            }}
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <FormControl
                          className={classes.input}
                          error={Boolean(errors.phone)}
                        >
                          <FormLabel className={classes.label}>
                            Mobile number
                          </FormLabel>

                          <Controller
                            render={({ onChange, value }) => (
                              <MuiPhoneNumber
                                defaultCountry={"nz"}
                                onlyCountries={["nz", "au"]}
                                enableLongNumbers
                                placeholder="Enter mobile number"
                                fullWidth
                                variant="filled"
                                size="small"
                                countryCodeEditable={false}
                                value={value}
                                onChange={(v) => {
                                  onChange(v);
                                  clearErrors("phone");
                                }}
                                error={errors.phone ? true : false}
                                helperText={errors.phone?.message}
                                FormHelperTextProps={{
                                  className: classes.error,
                                }}
                              />
                            )}
                            name="phone"
                            control={control}
                            defaultValue=""
                            rules={{
                              required: "Required",
                            }}
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <FormControl
                          error={Boolean(errors.location)}
                          className={classes.input}
                          size="small"
                        >
                          <FormLabel className={classes.label}>
                            Preferred Work Location
                          </FormLabel>

                          <Controller
                            as={
                              <Select displayEmpty={true} variant="filled">
                                <MenuItem value="">Select a location</MenuItem>
                                <MenuItem value="Any">Any</MenuItem>
                                <ListSubheader>Northland</ListSubheader>
                                <MenuItem value="Far North">Far North</MenuItem>
                                <MenuItem value="Kaipara">Kaipara</MenuItem>
                                <MenuItem value="Whangerei">Whangerei</MenuItem>

                                <ListSubheader>Auckland</ListSubheader>
                                <MenuItem value="Auckland City">
                                  Auckland City
                                </MenuItem>
                                <MenuItem value="Franklin">Franklin</MenuItem>
                                <MenuItem value="Hauraki Gulf Island">
                                  Hauraki Gulf Island
                                </MenuItem>
                                <MenuItem value="Manukau City">
                                  Manukau City
                                </MenuItem>
                                <MenuItem value="North Shore City">
                                  North Shore City
                                </MenuItem>
                                <MenuItem value="Papakura">Papakura</MenuItem>
                                <MenuItem value="Rodney">Rodney</MenuItem>
                                <MenuItem value="Waiheke Island">
                                  Waiheke Island
                                </MenuItem>
                                <MenuItem value="Waitakere City">
                                  Waitakere City
                                </MenuItem>

                                <ListSubheader>Waikato</ListSubheader>
                                <MenuItem value="Hamilton">Hamilton</MenuItem>
                                <MenuItem value="Hauraki">Hauraki</MenuItem>
                                <MenuItem value="Matamata-Piako">
                                  Matamata-Piako
                                </MenuItem>
                                <MenuItem value="Otorohonga">
                                  Otorohonga
                                </MenuItem>
                                <MenuItem value="South Waikato">
                                  South Waikato
                                </MenuItem>
                                <MenuItem value="Taupo">Taupo</MenuItem>
                                <MenuItem value="Thames-Coromandel">
                                  Thames-Coromandel
                                </MenuItem>
                                <MenuItem value="Waikato">Waikato</MenuItem>
                                <MenuItem value="Waipa">Waipa</MenuItem>
                                <MenuItem value="Waitomo">Waitomo</MenuItem>

                                <ListSubheader>Bay of plenty</ListSubheader>
                                <MenuItem value="Kawerau">Kawerau</MenuItem>
                                <MenuItem value="Opotiki">Opotiki</MenuItem>
                                <MenuItem value="Rotorua">Rotorua</MenuItem>
                                <MenuItem value="Tauranga">Tauranga</MenuItem>
                                <MenuItem value="Wester bay of plenty">
                                  Wester bay of plenty
                                </MenuItem>
                                <MenuItem value="Whakatane">Whakatane</MenuItem>

                                <ListSubheader>Gisborne</ListSubheader>
                                <MenuItem value="Gisborne">Gisborne</MenuItem>

                                <ListSubheader>Hawkes Bay</ListSubheader>
                                <MenuItem value="Central Hawkes Bay">
                                  Central Hawkes Bay
                                </MenuItem>
                                <MenuItem value="Hastings">Hastings</MenuItem>
                                <MenuItem value="Napier">Napier</MenuItem>
                                <MenuItem value="Wairoa">Wairoa</MenuItem>

                                <ListSubheader>Taranaki</ListSubheader>
                                <MenuItem value="New Plymouth">
                                  New Plymouth
                                </MenuItem>
                                <MenuItem value="South Taranaki">
                                  South Taranaki
                                </MenuItem>
                                <MenuItem value="Stratford">Stratford</MenuItem>

                                <ListSubheader>Manawatu</ListSubheader>
                                <MenuItem value="Horowhenua">
                                  Horowhenua
                                </MenuItem>
                                <MenuItem value="Manawatu">Manawatu</MenuItem>
                                <MenuItem value="Palmerston North">
                                  Palmerston North
                                </MenuItem>
                                <MenuItem value="Rangitikei">
                                  Rangitikei
                                </MenuItem>
                                <MenuItem value="Ruapehu">Ruapehu</MenuItem>
                                <MenuItem value="Tararua">Tararua</MenuItem>
                                <MenuItem value="Whanganui">Whanganui</MenuItem>

                                <ListSubheader>Wellington</ListSubheader>
                                <MenuItem value="Carterton">Carterton</MenuItem>
                                <MenuItem value="Kapiti Coast">
                                  Kapiti Coast
                                </MenuItem>
                                <MenuItem value="Lower Hutt">
                                  Lower Hutt
                                </MenuItem>
                                <MenuItem value="Masterton">Masterton</MenuItem>
                                <MenuItem value="Porirua">Porirua</MenuItem>
                                <MenuItem value="South Wairarapa">
                                  South Wairarapa
                                </MenuItem>
                                <MenuItem value="Upper Hutt">
                                  Upper Hutt
                                </MenuItem>
                                <MenuItem value="Wellington">
                                  Wellington
                                </MenuItem>

                                <ListSubheader>Nelson / Tasman</ListSubheader>
                                <MenuItem value="Nelson">Nelson</MenuItem>
                                <MenuItem value="Tasman">Tasman</MenuItem>

                                <ListSubheader>Marlborough</ListSubheader>
                                <MenuItem value="Blenheim">Blenheim</MenuItem>
                                <MenuItem value="Kaikoura">Kaikoura</MenuItem>
                                <MenuItem value="Marlborough">
                                  Marlborough
                                </MenuItem>

                                <ListSubheader>West Coast</ListSubheader>
                                <MenuItem value="Buller">Buller</MenuItem>
                                <MenuItem value="Grey">Grey</MenuItem>
                                <MenuItem value="Westland">Westland</MenuItem>

                                <ListSubheader>Canterbury</ListSubheader>
                                <MenuItem value="Ashburton">Ashburton</MenuItem>
                                <MenuItem value="Banks Peninsula">
                                  Banks Peninsula
                                </MenuItem>
                                <MenuItem value="Christchurch City">
                                  Christchurch City
                                </MenuItem>
                                <MenuItem value="Hurunui">Hurunui</MenuItem>
                                <MenuItem value="Mackenzie">Mackenzie</MenuItem>
                                <MenuItem value="Selwyn">Selwyn</MenuItem>
                                <MenuItem value="Timaru">Timaru</MenuItem>
                                <MenuItem value="Waimakariri">
                                  Waimakariri
                                </MenuItem>
                                <MenuItem value="Waimate">Waimate</MenuItem>

                                <ListSubheader>Otago</ListSubheader>
                                <MenuItem value="Central Otago">
                                  Central Otago
                                </MenuItem>
                                <MenuItem value="Clutha">Clutha</MenuItem>
                                <MenuItem value="Dunedin">Dunedin</MenuItem>
                                <MenuItem value="Queenstown-Lakes">
                                  Queenstown-Lakes
                                </MenuItem>
                                <MenuItem value="South Otago">
                                  South Otago
                                </MenuItem>
                                <MenuItem value="Waitaki">Waitaki</MenuItem>
                                <MenuItem value="Wanaka">Wanaka</MenuItem>

                                <ListSubheader>Southland</ListSubheader>
                                <MenuItem value="Catlins">Catlins</MenuItem>
                                <MenuItem value="Gore">Gore</MenuItem>
                                <MenuItem value="Invercargill">
                                  Invercargill
                                </MenuItem>
                                <MenuItem value="Southland">Southland</MenuItem>
                              </Select>
                            }
                            name="location"
                            control={control}
                            defaultValue=""
                            rules={{
                              required: "Required",
                            }}
                          />
                          <FormHelperText>
                            {errors.location && errors.location.message}
                          </FormHelperText>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <FormControl
                          error={Boolean(errors.industry)}
                          className={classes.input}
                          size="small"
                        >
                          <FormLabel className={classes.label}>
                            Select an Industry
                          </FormLabel>

                          <Controller
                            as={
                              <Select displayEmpty={true} variant="filled">
                                <MenuItem value="">Select an industry</MenuItem>
                                <MenuItem value="Information Technology">
                                  Information Technology
                                </MenuItem>
                                <MenuItem value="Human Resources & Recruitment">
                                  Human Resources & Recruitment
                                </MenuItem>
                                <MenuItem value="Account and Finance">
                                  Accounting and Finance
                                </MenuItem>
                                <MenuItem value="Building/Demolition">
                                  Building/Demolition
                                </MenuItem>
                                <MenuItem value="Engineering">
                                  Engineering
                                </MenuItem>
                                <MenuItem value="Electrical">
                                  Electrical
                                </MenuItem>
                                <MenuItem value="Healthcare">
                                  Healthcare
                                </MenuItem>
                                <MenuItem value="Manufacturing">
                                  Manufacturing
                                </MenuItem>
                                <MenuItem value="Office Support">
                                  Office Support
                                </MenuItem>
                                <MenuItem value="Procurement and supplychain">
                                  Procurement and supplychain
                                </MenuItem>
                                <MenuItem value="Property and construction">
                                  Property and construction
                                </MenuItem>
                                <MenuItem value="Retail">Retail</MenuItem>
                                <MenuItem value="Sales">Sales</MenuItem>
                                <MenuItem value="Transport and Logistics">
                                  Transport and Logistics
                                </MenuItem>
                                <MenuItem value="Call centre and customer service">
                                  Call centre and customer service
                                </MenuItem>
                                <MenuItem value="Education and training">
                                  Education and training
                                </MenuItem>
                                <MenuItem value="Hospitality, Tourism and Travel">
                                  Hospitality, Tourism and Travel
                                </MenuItem>
                                <MenuItem value="Other">Other</MenuItem>
                              </Select>
                            }
                            name="industry"
                            control={control}
                            defaultValue=""
                            rules={{
                              required: "Required",
                            }}
                          />
                          <FormHelperText>
                            {errors.industry && errors.industry.message}
                          </FormHelperText>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} md={6}>
                        {watchIndustry === "Other" && (
                          <FormControl
                            className={classes.input}
                            error={Boolean(errors.industryOther)}
                          >
                            <FormLabel className={classes.label}>
                              Enter other
                            </FormLabel>

                            <Controller
                              as={
                                <TextField
                                  placeholder="Enter other"
                                  fullWidth
                                  variant="filled"
                                  size="small"
                                  error={errors.industryOther ? true : false}
                                  helperText={errors.industryOther?.message}
                                  FormHelperTextProps={{
                                    className: classes.error,
                                  }}
                                />
                              }
                              name="industryOther"
                              control={control}
                              defaultValue=""
                              rules={{
                                required: "Required",
                              }}
                            />
                          </FormControl>
                        )}
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FormControl
                          error={Boolean(errors.uploadCv)}
                          className={classes.input}
                          size="small"
                        >
                          <FormLabel className={classes.label}>
                            Do you want to Upload CV and/or cover letter.
                          </FormLabel>

                          <Controller
                            render={({ onChange, value }) => (
                              <Select
                                displayEmpty
                                variant="filled"
                                onChange={(e) => {
                                  let val = e.target.value as string;
                                  onChange(val);
                                  clearErrors("cvFile");
                                }}
                                value={value}
                              >
                                <MenuItem value="">Choose an option</MenuItem>
                                <MenuItem value="Yes">Yes</MenuItem>
                                <MenuItem value="No, Later">No, Later</MenuItem>
                              </Select>
                            )}
                            name="uploadCv"
                            control={control}
                            defaultValue=""
                            rules={{
                              required: "Required",
                            }}
                          />
                          <FormHelperText>
                            {errors.uploadCv && errors.uploadCv.message}
                          </FormHelperText>
                        </FormControl>
                      </Grid>

                      {watch("uploadCv") === "Yes" && (
                        <Grid item xs={12}>
                          <FormControl
                            error={Boolean(errors.cvFile)}
                            className={classes.input}
                          >
                            <FormLabel className={classes.label}>
                              Upload CV
                            </FormLabel>
                            <Controller
                              control={control}
                              name="cvFile"
                              defaultValue=""
                              rules={{
                                required: true,
                              }}
                              render={() => (
                                <div>
                                  <Dropzone
                                    onDrop={handleDrop}
                                    multiple={false}
                                    accept={[
                                      "image/jpeg",
                                      "image/png",
                                      "application/pdf",
                                      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                                    ]}
                                  >
                                    {({ getRootProps, getInputProps }) => (
                                      <Paper
                                        variant="outlined"
                                        {...getRootProps({
                                          className: cvFileDropzoneClass(),
                                        })}
                                      >
                                        <input
                                          {...getInputProps()}
                                          name="cvFile"
                                        />
                                        <p>
                                          Drag 'n' drop files here, or click to
                                          select files
                                        </p>
                                      </Paper>
                                    )}
                                  </Dropzone>
                                  {uploadedCvFile.length !== 0 && (
                                    <List>
                                      {uploadedCvFile.map(
                                        (f: File, index: number) => (
                                          <ListItem key={index}>
                                            <ListItemIcon>
                                              <InsertDriveFile />
                                            </ListItemIcon>
                                            <ListItemText
                                              primary={f.name}
                                              secondary={calcSize(f.size)}
                                            />
                                          </ListItem>
                                        )
                                      )}
                                    </List>
                                  )}
                                </div>
                              )}
                            />
                            <FormHelperText>
                              {errors.cvFile && "Required"}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                      )}
                    </Grid>
                  </div>

                  {error && (
                    <div style={{ color: "red", marginBottom: 16 }}>
                      {error}
                    </div>
                  )}
                  {registerLoading ? (
                    <Button
                      style={{ width: "100%", padding: 16 }}
                      variant="contained"
                      color="primary"
                    >
                      <CircularProgress size={25} style={{ color: "white" }} />
                    </Button>
                  ) : (
                    <Button
                      style={{ width: "100%", padding: 16 }}
                      variant="contained"
                      color="primary"
                      onClick={handleSubmit(onSubmit)}
                    >
                      <h5>Register</h5>
                    </Button>
                  )}
                </div>
              </Card>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h4>Already have an account?</h4>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginLeft: 16 }}
                  onClick={() => navigate("/")}
                >
                  Sign in
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <>
            {confirmSignUp && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100vh",
                }}
              >
                <ConfirmRegistration
                  auth={auth}
                  data={data!}
                  cognitoUser={cognitoUser}
                />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

interface ConfirmRegistrationProps {
  auth: MyAuth;
  data: Inputs;
  cognitoUser: any | undefined;
}

function ConfirmRegistration({
  auth,
  data,
  cognitoUser,
}: ConfirmRegistrationProps) {
  const classes = useStyles();

  const [confirmationCode, setConfirmationCode] = useState<string>();
  const [confirmSignUpLoading, setConfirmSignUpLoading] =
    useState<boolean>(false);

  const navigate = useNavigate();

  const verifyUser = async () => {
    if (confirmationCode !== "") {
      setConfirmSignUpLoading(true);
      let params = {
        username: data.email,
        code: confirmationCode,
      };
      auth
        .confirmSignUp(params)
        .then(() => {
          if (cognitoUser) {
            localStorage.setItem("lowie-welcome-dialog", "true");
            Auth.signIn(data.email, data.password)
              .then((user: any) => {
                auth.setUser(user);
                createCandidate(cognitoUser.userSub, data);
              })
              .catch((error: any) => console.log("Error: signing in", error));
          }
        })
        .catch((error: any) => {
          console.log("ERROR: Verifying sign up", error);
          setConfirmSignUpLoading(false);
        });
    }
  };

  const createCandidate = (username: string, data: Inputs) => {
    let params = {
      body: {
        id: username,
        data: {
          title: data.title,
          firstName: data.firstName,
          surname: data.surname,
          phone: data.phone,
          email: data.email,
          industry: data.industry,
          industryOther: data.industryOther,
          location: data.location,
          cvFile: data.cvFile,
        },
      },
    };

    API.post("", "/candidates", params)
      .then(() => {
        API.get("", `/candidates/${username}`, {})
          .then((response: Candidate) => {
            setConfirmSignUpLoading(false);
            if (response) {
              auth.setCandidate(response);
              navigate("/", { replace: true });
            }
          })
          .catch((error: any) => {
            console.log("Error: fetching candidate by id", error);
            setConfirmSignUpLoading(false);
          });
      })
      .catch((error: any) => {
        console.log("ERROR adding candidate", error);
        setConfirmSignUpLoading(false);
      });
  };

  return (
    <Card style={{ padding: 16, width: 650 }}>
      <h3 style={{ marginBottom: 32 }}>Confirm Registration</h3>

      <p style={{ marginBottom: 16 }}>
        An sms has been sent to you with a verification code.
      </p>
      <FormControl className={classes.input} style={{ marginBottom: 16 }}>
        <FormLabel className={classes.label}>Verification code</FormLabel>

        <TextField
          placeholder="Enter code"
          fullWidth
          variant="filled"
          size="small"
          value={confirmationCode}
          onChange={(event) => {
            let newVal = event.target.value;
            setConfirmationCode(newVal);
          }}
          FormHelperTextProps={{ className: classes.error }}
        />
      </FormControl>

      <a
        href="#"
        onClick={(event) => {
          event.preventDefault();
          auth.resendSignUp(data!.email);
        }}
      >
        Resend Code
      </a>

      <div style={{ display: "flex" }}>
        <div style={{ flexGrow: 1 }}></div>
        {confirmSignUpLoading ? (
          <Button
            className={classes.submit}
            variant="contained"
            color="primary"
          >
            <CircularProgress size={25} style={{ color: "white" }} />
          </Button>
        ) : (
          <Button
            className={classes.submit}
            variant="contained"
            color="primary"
            onClick={() => verifyUser()}
          >
            Confirm
          </Button>
        )}
      </div>
    </Card>
  );
}
