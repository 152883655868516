import React, { useState } from 'react'
import {
    Link, Button
} from "@material-ui/core";
import { Candidate } from '../../CandidateTypes';
import { API } from "aws-amplify";
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

interface Props {
    // incrementActiveStep: () => void;
    // activeStep: number
    candidate: Candidate | undefined
}

export default function ContractAgreement({  candidate }: Props) {

    const [loading, setLoading] = useState<boolean>(false)
    const [numPages, setNumPages] = useState(1);
    const [pageNumber, setPageNumber] = useState(1);

    const onSubmit = () => {
        if(candidate) {
            setLoading(true)
            let params = {
                body: {
                    data: {},
                    // step: activeStep,
                    id: candidate.username
                }
            }
            API.post("", '/candidates/jobPlacement/update', params)
            .then(() => {
                setLoading(false)
                // incrementActiveStep()
            })
            .catch((error: any) => {
                console.log("Error: submitting emplyoment contract", error)
                setLoading(false)
            })
        }
    }

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setPageNumber(1);
      }
    
      function changePage(offset) {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
      }
    
      function previousPage() {
        changePage(-1);
      }
    
      function nextPage() {
        changePage(1);
      }

    return (
        <div>
            <h3 style={{marginBottom: 16}}>Independent Contractor Agreement</h3>
            

            <div >
                <Document
                    file="/docs/Lowie-Recruitment-Independent-Contractor-Agreement.pdf"
                    onLoadSuccess={onDocumentLoadSuccess}
                >
                    <Page pageNumber={pageNumber} />
                    {/* {Array.from(new Array(numPages), (el, index) => (
                    <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                ))} */}
			</Document>
            <div>
        <p>
          Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
        </p>
        <button
          type="button"
          disabled={pageNumber <= 1}
          onClick={previousPage}
        >
          Previous
        </button>
        <button
          type="button"
          disabled={pageNumber >= numPages}
          onClick={nextPage}
        >
          Next
        </button>
      </div>
            </div>
            <div>
                <p>By clicking next you agree to the terms and conditions stated in the Independent Contractor Agreement below.</p>
                <Link href="/docs/Lowie-Recruitment-Independent-Contractor-Agreement.pdf" target='_blank'>
                Download a copy of the Independent Contractor Agreement
                </Link>
            </div>

            <div>
                <Button style={{marginTop: 16}} variant="contained" color="primary" onClick={() => onSubmit()} disabled={pageNumber  < numPages}>
                    Agree and Accept
                </Button>
            </div>
        </div>
    )
}
