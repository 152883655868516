import React, { useState } from "react";
import {
  Button, Stepper, Step, StepLabel, MobileStepper, Hidden,
  Divider, FormControl, RadioGroup, Radio, FormControlLabel,
  CircularProgress
} from "@material-ui/core"
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useForm, Controller } from "react-hook-form"
import { useStopwatch } from "react-timer-hook";
import "../../App.css"
import { Timer } from "@material-ui/icons"
import { Candidate } from '../../CandidateTypes';
import { API } from "aws-amplify";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiTextField-root': {
        marginBottom: theme.spacing(3),
        width: "100%"
      },
      '& .MuiInput-root': {
        marginBottom: theme.spacing(3),
        width: "100%"
      }
    },
    card: {
      backgroundColor: "#ffffff",
      padding: 16,
      width: "100%"
    },
    submit: {
      marginTop: 32,
      width: 100
    },
    select: {
      marginBottom: theme.spacing(3),
      width: "100%"
    },
    backButton: {
      width: 80,
      marginRight: theme.spacing(1),
    },
    instructions: {
      margin: theme.spacing(1)
    },
    vl: {
      marginLeft: 32,
      marginRight: 32,
      borderLeft: "1px solid grey"
    },
    stepper: {
      backgroundColor: "#f4f4f4",
      marginTop: 8
    },
    form: {
      [theme.breakpoints.up("sm")] : {
        marginTop: 16,
        marginLeft: 50
      }
    },
    question: {
      marginTop: 8
    },
    textQuestion: {
      marginTop: 32
    },
    options: {
      marginTop: 8
    },
    iconContainer: {
      transform: 'scale(1)',
    }
  }),
);

export interface FormQuestions {
  q1: Question;
  q2: Question;
  q3: Question;
  q4: Question;
  q5: Question;
  q6: Question;
  q7: Question;
  q8: Question;
  q9: Question;
  q10: Question;
  q11: Question;
  q12: Question;
  q13: Question;
  q14: Question;
  q15: Question;
  q16: Question;
  q17: Question;
  q18: Question;
  q19: Question;
  q20: Question;
  q21: Question;
  q22: Question;
  q23: Question;
  q24: Question;
  q25: Question;
  q26: Question;
  q27: Question;
  q28: Question;
}

interface Question {
  question: string;
  answer: string;
}

interface Props {
  candidate: Candidate | undefined
}

const IndustrialTest = ({ candidate } : Props) =>{
    const classes = useStyles();
    const steps = getSteps();

    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string>()

    const { seconds, minutes, pause } = useStopwatch({ autoStart: true })

    const [activeStep, setActiveStep] = React.useState(0);
    const [isFinished, setFinished] = React.useState(false);

    const { handleSubmit, watch, control, getValues } = useForm<FormQuestions>({
      shouldUnregister: false,
      defaultValues: {
        q1: {
          question: "A pallet has 7 layers of 12 cartons and 1 layer of 9 cartons. How many cartons on the pallet?",
          answer: ""
        },
        q2: {
          question: "There are 4 layers of 14 cartons and 3 layers of 16 cartons on a pallet. How many cartons on the pallet?",
          answer: ""
        },
        q3: {
          question: "A pallet has 7 layers of 14 cartons. How many cartons on the pallet?",
          answer: ""
        },
        q4: {
          question: "A pallet has 9 layers of 13 cartons. How many cartons on the pallet?",
          answer: ""
        },
        q5: {
          question: "You have 9 pallets, each have 34 cartons. How many cartons do you have?",
          answer: ""
        },
        q6: {
          question: "You have 42 cartons to stack on a pallet in 7 equal layers. How many cartons would you pack on each layer?",
          answer: ""
        },
        q7: {
          question: "You have 45 units and they are packed into cartons of 9. How many cartons have you got?",
          answer: ""
        },
        q8: {
          question: "Of 241 cartons, 68 are damaged. How many cartons are NOT damaged?",
          answer: ""
        },
        q9: {
          question: "You have received 7 pallets of cartons. 1 pallet has 21 cartons, 3 pallets have 12 cartons each, and 3 pallets have 18 cartons each. How many cartons did you receive?",
          answer: ""
        },
        q10: {
          question: "What is 6pm in 24 hour format",
          answer: ""
        },
        q11: {
          question: "What is 10am in 24 hour format",
          answer: ""
        },
        q12: {
          question: "What is 12pm (noon) in 24 hour format",
          answer: ""
        },
        q13: {
          question: "What is 2pm in 24 hour format",
          answer: ""
        },
        q14: {
          question: "Which is heavier 0.14kg or 1.4kg?",
          answer: ""
        },
        q15: {
          question: "Which is heavier 16kg or 1600 grams?",
          answer: ""
        },
        q16: {
          question: "Which is heavier 4 grams or 4 tonnes?",
          answer: ""
        },
        q17: {
          question: "Which is longer 6m or 16cm?",
          answer: ""
        },
        q18: {
          question: "Which is longer 1.2cm or 1200mm?",
          answer: ""
        },
        q19: {
          question: "Which is longer 4cm or 4mm",
          answer: ""
        },
        q20: {
          question: "1. When must ear protection be worn?",
          answer: ""
        },
        q21: {
          question: "2. When must protective clothing be worn?",
          answer: ""
        },
        q22: {
          question: "3. If you are working with machinery, when should you wear gloves?",
          answer: ""
        },
        q23: {
          question: "1. What is in the can labelled 2?",
          answer: ""
        },
        q24: {
          question: "2. What must you do if you put oil in the wrong can?",
          answer: ""
        },
        q25: {
          question: "3. Which can contain oil?",
          answer: ""
        },
        q26: {
          question: "1. How should you avoid accidents?",
          answer: ""
        },
        q27: {
          question: "2. What must you do before moving machinery?",
          answer: ""
        },
        q28: {
          question: "3. When should you run?",
          answer: ""
        },
      }
    });
    const onSubmit = (data: FormQuestions) => {
      if(candidate) {
        //pause timer
        pause();
        setLoading(true)
        let params = {
          body: {
            id: candidate.pk,
            data: data
          }
        }

        API.post("", "/candidates/industrialTest", params)
        .then(() => {
          setLoading(false)
          setFinished(true);
        })
        .catch((error: any) => {
          console.log("Error: posting test", error)
          setLoading(false)
          setError("Error: unable to submit test")
        })
      }
    }
  
    const handleNext = () => {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
  
    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
   
   
    function getSteps() {
      let arr = new Array(28);
      arr.fill("");
      return arr;
    }

    function getTimerMinutes() {
      if(minutes < 10) {
        return "0" + minutes;
      }
      return minutes;
    }

    function getTimerSeconds() {
      if(seconds < 10) {
        return "0" + seconds;
      }
      return seconds;
    }

    function getTime() {
      return `${minutes} mins ${seconds} secs`;
    }

    function getQuestion() : string {
      switch(activeStep) {
        case 0: {
          return "q1.answer"
        }
        case 1: {
          return "q2.answer"
        }
        case 2: {
          return "q3.answer"
        }
        case 3: {
          return "q4.answer"
        }
        case 4: {
          return "q5.answer"
        }
        case 5: {
          return "q6.answer"
        }
        case 6: {
          return "q7.answer"
        }
        case 7: {
          return "q8.answer"
        }
        case 8: {
          return "q9.answer"
        }
        case 9: {
          return "q10.answer"
        }
        case 10: {
          return "q11.answer"
        }
        case 11: {
          return "q12.answer"
        }
        case 12: {
          return "q13.answer"
        }
        case 13: {
          return "q14.answer"
        }
        case 14: {
          return "q15.answer"
        }
        case 15: {
          return "q16.answer"
        }
        case 16: {
          return "q17.answer"
        }
        case 17: {
          return "q18.answer"
        }
        case 18: {
          return "q19.answer"
        }
        case 19: {
          return "q20.answer"
        }
        case 20: {
          return "q21.answer"
        }
        case 21: {
          return "q22.answer"
        }
        case 22: {
          return "q23.answer"
        }
        case 23: {
          return "q24.answer"
        }
        case 24: {
          return "q25.answer"
        }
        case 25: {
          return "q26.answer"
        }
        case 26: {
          return "q27.answer"
        }
        case 27: {
          return "q28.answer"
        }
        default:
          return ""
      }
    }

    // function getActiveStep() : number {
    //   return state.data.activeStep
    // }

    function getStepContent(stepIndex: number) : JSX.Element {
      switch(stepIndex) {
        case 0:
          return (
            <FormControl 
              className={classes.form}>
                
              <p className={classes.question}>
                {getValues("q1.question")}
              </p>

              <Controller
                as={
                  <RadioGroup>
                      <FormControlLabel value="93" control={<Radio />} label="93"/>
                      <FormControlLabel value="117" control={<Radio />} label="117"/>
                      <FormControlLabel value="28" control={<Radio />} label="28"/>
                      <FormControlLabel value="75" control={<Radio />} label="75"/>
                  </RadioGroup>
                }
                name="q1.answer"
                control={control}
                defaultValue=""
                className={classes.options}
                
              />
          </FormControl>
        )
      case 1:
        return (
          <FormControl 
            className={classes.form}>
              
            <p className={classes.question}>
              {getValues("q2.question")}
            </p>

            <Controller
              as={
                <RadioGroup>
                    <FormControlLabel value="36" control={<Radio />} label="36"/>
                    <FormControlLabel value="104" control={<Radio />} label="104"/>
                    <FormControlLabel value="106" control={<Radio />} label="106"/>
                    <FormControlLabel value="75" control={<Radio />} label="75"/>
                </RadioGroup>
              }
              name="q2.answer"
              control={control}
              defaultValue=""
              className={classes.options}
              
            />
          </FormControl>
        )
      case 2:
        return (
          <FormControl 
            className={classes.form}>
              
            <p className={classes.question}>
              {getValues("q3.question")}
            </p>

            <Controller
              as={
                <RadioGroup>
                    <FormControlLabel value="36" control={<Radio />} label="36"/>
                    <FormControlLabel value="105" control={<Radio />} label="105"/>
                    <FormControlLabel value="84" control={<Radio />} label="84"/>
                    <FormControlLabel value="98" control={<Radio />} label="98"/>
                </RadioGroup>
              }
              name="q3.answer"
              control={control}
              defaultValue=""
              className={classes.options}
              
            />
          </FormControl>
        )
      case 3:
        return (
          <FormControl 
            className={classes.form}>
              
            <p className={classes.question}>
              {getValues("q4.question")}
            </p>

            <Controller
              as={
                <RadioGroup>
                    <FormControlLabel value="108" control={<Radio />} label="108"/>
                    <FormControlLabel value="117" control={<Radio />} label="117"/>
                    <FormControlLabel value="22" control={<Radio />} label="22"/>
                    <FormControlLabel value="126" control={<Radio />} label="126"/>
                </RadioGroup>
              }
              name="q4.answer"
              control={control}
              defaultValue=""
              className={classes.options}
              
            />
          </FormControl>
        )
        case 4:
          return (
            <FormControl 
              className={classes.form}>
                
              <p className={classes.question}>
                {getValues("q5.question")}
              </p>
  
              <Controller
                as={
                  <RadioGroup>
                      <FormControlLabel value="43" control={<Radio />} label="43"/>
                      <FormControlLabel value="297" control={<Radio />} label="297"/>
                      <FormControlLabel value="306" control={<Radio />} label="306"/>
                      <FormControlLabel value="340" control={<Radio />} label="340"/>
                  </RadioGroup>
                }
                name="q5.answer"
                control={control}
                defaultValue=""
                className={classes.options}
                
              />
            </FormControl>
          )
        case 5:
          return (
            <FormControl 
              className={classes.form}>
                
              <p className={classes.question}>
                {getValues("q6.question")}
              </p>
  
              <Controller
                as={
                  <RadioGroup>
                      <FormControlLabel value="7" control={<Radio />} label="7"/>
                      <FormControlLabel value="6" control={<Radio />} label="6"/>
                      <FormControlLabel value="5" control={<Radio />} label="5"/>
                      <FormControlLabel value="8" control={<Radio />} label="8"/>
                  </RadioGroup>
                }
                name="q6.answer"
                control={control}
                defaultValue=""
                className={classes.options}
                
              />
            </FormControl>
          )
        case 6:
          return (
            <FormControl 
              className={classes.form}>
                
              <p className={classes.question}>
                {getValues("q7.question")}
              </p>
  
              <Controller
                as={
                  <RadioGroup>
                      <FormControlLabel value="54" control={<Radio />} label="54"/>
                      <FormControlLabel value="36" control={<Radio />} label="36"/>
                      <FormControlLabel value="5" control={<Radio />} label="5"/>
                      <FormControlLabel value="405" control={<Radio />} label="405"/>
                  </RadioGroup>
                }
                name="q7.answer"
                control={control}
                defaultValue=""
                className={classes.options}
                
              />
            </FormControl>
          )
        case 7:
          return (
            <FormControl 
              className={classes.form}>
                
              <p className={classes.question}>
                {getValues("q8.question")}
              </p>
  
              <Controller
                as={
                  <RadioGroup>
                      <FormControlLabel value="173" control={<Radio />} label="173"/>
                      <FormControlLabel value="68" control={<Radio />} label="68"/>
                      <FormControlLabel value="309" control={<Radio />} label="309"/>
                      <FormControlLabel value="241" control={<Radio />} label="241"/>
                  </RadioGroup>
                }
                name="q8.answer"
                control={control}
                defaultValue=""
                className={classes.options}
                
              />
            </FormControl>
          )
        case 8:
          return (
            <FormControl 
              className={classes.form}>
                
              <p className={classes.question}>
                {getValues("q9.question")}
              </p>
  
              <Controller
                as={
                  <RadioGroup>
                      <FormControlLabel value="109" control={<Radio />} label="109"/>
                      <FormControlLabel value="58" control={<Radio />} label="58"/>
                      <FormControlLabel value="94" control={<Radio />} label="94"/>
                      <FormControlLabel value="111" control={<Radio />} label="111"/>
                  </RadioGroup>
                }
                name="q9.answer"
                control={control}
                defaultValue=""
                className={classes.options}
                
              />
            </FormControl>
          )
        case 9:
          return (
            <FormControl 
              className={classes.form}>
                
              <p className={classes.question}>
                {getValues("q10.question")}
              </p>
  
              <Controller
                as={
                  <RadioGroup>
                      <FormControlLabel value="1800" control={<Radio />} label="1800"/>
                      <FormControlLabel value="0600" control={<Radio />} label="0600"/>
                      <FormControlLabel value="1600" control={<Radio />} label="1600"/>
                      <FormControlLabel value="2000" control={<Radio />} label="2000"/>
                  </RadioGroup>
                }
                name="q10.answer"
                control={control}
                defaultValue=""
                className={classes.options}
                
              />
            </FormControl>
          )
        case 10:
          return (
            <FormControl 
              className={classes.form}>
                
              <p className={classes.question}>
                {getValues("q11.question")}
              </p>
  
              <Controller
                as={
                  <RadioGroup>
                      <FormControlLabel value="2200" control={<Radio />} label="2200"/>
                      <FormControlLabel value="1000" control={<Radio />} label="1000"/>
                      <FormControlLabel value="2000" control={<Radio />} label="2000"/>
                      <FormControlLabel value="1600" control={<Radio />} label="1600"/>
                  </RadioGroup>
                }
                name="q11.answer"
                control={control}
                defaultValue=""
                className={classes.options}
                
              />
            </FormControl>
          )
        case 11:
          return (
            <FormControl 
              className={classes.form}>
                
              <p className={classes.question}>
                {getValues("q12.question")}
              </p>
  
              <Controller
                as={
                  <RadioGroup>
                      <FormControlLabel value="2400" control={<Radio />} label="2400"/>
                      <FormControlLabel value="0000" control={<Radio />} label="0000"/>
                      <FormControlLabel value="1200" control={<Radio />} label="1200"/>
                      <FormControlLabel value="0030" control={<Radio />} label="0030"/>
                  </RadioGroup>
                }
                name="q12.answer"
                control={control}
                defaultValue=""
                className={classes.options}
                
              />
            </FormControl>
          )
        case 12:
          return (
            <FormControl 
              className={classes.form}>
                
              <p className={classes.question}>
                {getValues("q13.question")}
              </p>
  
              <Controller
                as={
                  <RadioGroup>
                      <FormControlLabel value="0200" control={<Radio />} label="0200"/>
                      <FormControlLabel value="1200" control={<Radio />} label="1200"/>
                      <FormControlLabel value="1400" control={<Radio />} label="1400"/>
                      <FormControlLabel value="0600" control={<Radio />} label="1000"/>
                  </RadioGroup>
                }
                name="q13.answer"
                control={control}
                defaultValue=""
                className={classes.options}
                
              />
            </FormControl>
          )
        case 13:
          return (
            <FormControl 
              className={classes.form}>
                
              <p className={classes.question}>
                {getValues("q14.question")}
              </p>
  
              <Controller
                as={
                  <RadioGroup>
                      <FormControlLabel value="0.14kg" control={<Radio />} label="0.14kg"/>
                      <FormControlLabel value="1.4kg" control={<Radio />} label="1.4kg"/>
                      <FormControlLabel value="They are equal" control={<Radio />} label="They are equal"/>
                  </RadioGroup>
                }
                name="q14.answer"
                control={control}
                defaultValue=""
                className={classes.options}
                
              />
            </FormControl>
          )
        case 14:
          return (
            <FormControl 
              className={classes.form}>
                
              <p className={classes.question}>
                {getValues("q15.question")}
              </p>
  
              <Controller
                as={
                  <RadioGroup>
                      <FormControlLabel value="16kg" control={<Radio />} label="16kg"/>
                      <FormControlLabel value="1600 grams" control={<Radio />} label="1600 grams"/>
                      <FormControlLabel value="They are equal" control={<Radio />} label="They are equal"/>
                  </RadioGroup>
                }
                name="q15.answer"
                control={control}
                defaultValue=""
                className={classes.options}
                
              />
            </FormControl>
          )
        case 15:
          return (
            <FormControl 
              className={classes.form}>
                
              <p className={classes.question}>
                {getValues("q16.question")}
              </p>
  
              <Controller
                as={
                  <RadioGroup>
                      <FormControlLabel value="4 grams" control={<Radio />} label="4 grams"/>
                      <FormControlLabel value="4 tonnes" control={<Radio />} label="4 tonnes"/>
                      <FormControlLabel value="They are equal" control={<Radio />} label="They are equal"/>
                  </RadioGroup>
                }
                name="q16.answer"
                control={control}
                defaultValue=""
                className={classes.options}
                
              />
            </FormControl>
          )
        case 16:
          return (
            <FormControl 
              className={classes.form}>
                
              <p className={classes.question}>
                {getValues("q17.question")}
              </p>
  
              <Controller
                as={
                  <RadioGroup>
                      <FormControlLabel value="6m" control={<Radio />} label="6m"/>
                      <FormControlLabel value="16cm" control={<Radio />} label="16cm"/>
                      <FormControlLabel value="They are equal" control={<Radio />} label="They are equal"/>
                  </RadioGroup>
                }
                name="q17.answer"
                control={control}
                defaultValue=""
                className={classes.options}
                
              />
            </FormControl>
          )
        case 17:
          return (
            <FormControl 
              className={classes.form}>
                
              <p className={classes.question}>
                {getValues("q18.question")}
              </p>
  
              <Controller
                as={
                  <RadioGroup>
                      <FormControlLabel value="1.2cm" control={<Radio />} label="1.2cm"/>
                      <FormControlLabel value="1200mm" control={<Radio />} label="1200mm"/>
                      <FormControlLabel value="They are equal" control={<Radio />} label="They are equal"/>
                  </RadioGroup>
                }
                name="q18.answer"
                control={control}
                defaultValue=""
                className={classes.options}
                
              />
            </FormControl>
          )
        case 18:
          return (
            <FormControl 
              className={classes.form}>
                
              <p className={classes.question}>
                {getValues("q19.question")}
              </p>
  
              <Controller
                as={
                  <RadioGroup>
                      <FormControlLabel value="4cm" control={<Radio />} label="4cm"/>
                      <FormControlLabel value="4mm" control={<Radio />} label="4mm"/>
                      <FormControlLabel value="They are equal" control={<Radio />} label="They are equal"/>
                  </RadioGroup>
                }
                name="q19.answer"
                control={control}
                defaultValue=""
                className={classes.options}
                
              />
            </FormControl>
          )
        case 19:
          return (
            <FormControl 
              className={classes.form}>
                
              <p>
                Protective clothing must be worn when working with machinery. Gloves must be worn when handling hot or sharp machine parts. Ear protection must be worn when the noise level is above 85dB. Overalls must be worn at all times when working with machinery.
              </p>

              <p className={classes.textQuestion}>
                {getValues("q20.question")}
              </p>
  
              <Controller
                as={
                  <RadioGroup>
                      <FormControlLabel value="When handling sharp machine parts" control={<Radio />} label="When handling sharp machine parts"/>
                      <FormControlLabel value="When working with any machinery" control={<Radio />} label="When working with any machinery"/>
                      <FormControlLabel value="When handling hot machinery" control={<Radio />} label="When handling hot machinery"/>
                      <FormControlLabel value="When the noise level is above 85bB" control={<Radio />} label="When the noise level is above 85bB"/>
                  </RadioGroup>
                }
                name="q20.answer"
                control={control}
                defaultValue=""
                className={classes.options}
                
              />
            </FormControl>
          )
        case 20:
          return (
            <FormControl 
              className={classes.form}>
                
              <p>
                Protective clothing must be worn when working with machinery. Gloves must be worn when handling hot or sharp machine parts. Ear protection must be worn when the noise level is above 85dB. Overalls must be worn at all times when working with machinery.
              </p>

              <p className={classes.textQuestion}>
                {getValues("q21.question")}
              </p>

              <Controller
                as={
                  <RadioGroup>
                      <FormControlLabel value="When working with machinery" control={<Radio />} label="When working with machinery"/>
                      <FormControlLabel value="When there are no gloves or overalls" control={<Radio />} label="When there are no gloves or overalls"/>
                      <FormControlLabel value="When the supervisor is present" control={<Radio />} label="When the supervisor is present"/>
                      <FormControlLabel value="When the noise level is below 80dB" control={<Radio />} label="When the noise level is below 80dB"/>
                  </RadioGroup>
                }
                name="q21.answer"
                control={control}
                defaultValue=""
                className={classes.options}
                
              />
            </FormControl>
          )
        case 21:
          return (
            <FormControl 
              className={classes.form}>
                
              <p>
                Protective clothing must be worn when working with machinery. Gloves must be worn when handling hot or sharp machine parts. Ear protection must be worn when the noise level is above 85dB. Overalls must be worn at all times when working with machinery.
              </p>

              <p className={classes.textQuestion}>
                {getValues("q22.question")}
              </p>


              <Controller
                as={
                  <RadioGroup>
                      <FormControlLabel value="When the supervisor says so" control={<Radio />} label="When the supervisor says so"/>
                      <FormControlLabel value="At all times" control={<Radio />} label="At all times"/>
                      <FormControlLabel value="When handling hot or sharp machine parts" control={<Radio />} label="When handling hot or sharp machine parts"/>
                      <FormControlLabel value="When overalls and ear protection are worn" control={<Radio />} label="When overalls and ear protection are worn"/>
                  </RadioGroup>
                }
                name="q22.answer"
                control={control}
                defaultValue=""
                className={classes.options}
                
              />
            </FormControl>
          )
        case 22:
          return (
            <FormControl 
              className={classes.form}>
                
              <p>
                The can labelled1 contains oil. The can labelled2 contains water. You must only put oil in the can labelled 1. You must only put water in the can labelled2. If you put oil or water in the wrong can, then you must tell your supervisor.
              </p>

              <p className={classes.textQuestion}>
                {getValues("q23.question")}
              </p>


              <Controller
                as={
                  <RadioGroup>
                      <FormControlLabel value="Oil" control={<Radio />} label="Oil"/>
                      <FormControlLabel value="Water" control={<Radio />} label="Water"/>
                      <FormControlLabel value="Oil and Water" control={<Radio />} label="Oil and Water"/>
                      <FormControlLabel value="Two liquids" control={<Radio />} label="Two liquids"/>
                  </RadioGroup>
                }
                name="q23.answer"
                control={control}
                defaultValue=""
                className={classes.options}
                
              />
            </FormControl>
          )
        case 23:
          return (
            <FormControl 
              className={classes.form}>
                
              <p>
                The can labelled1 contains oil. The can labelled2 contains water. You must only put oil in the can labelled 1. You must only put water in the can labelled2. If you put oil or water in the wrong can, then you must tell your supervisor.
              </p>

              <p className={classes.textQuestion}>
                {getValues("q24.question")}
              </p>


              <Controller
                as={
                  <RadioGroup>
                      <FormControlLabel value="Put water in the can" control={<Radio />} label="Put water in the can"/>
                      <FormControlLabel value="Change the label on the can" control={<Radio />} label="Change the label on the can"/>
                      <FormControlLabel value="Put oil in the can marked 2" control={<Radio />} label="Put oil in the can marked 2"/>
                      <FormControlLabel value="Tell your supervisor" control={<Radio />} label="Tell your supervisor"/>
                  </RadioGroup>
                }
                name="q24.answer"
                control={control}
                defaultValue=""
                className={classes.options}
                
              />
            </FormControl>
          )
        case 24:
          return (
            <FormControl 
              className={classes.form}>
                
              <p>
                The can labelled1 contains oil. The can labelled2 contains water. You must only put oil in the can labelled 1. You must only put water in the can labelled2. If you put oil or water in the wrong can, then you must tell your supervisor.
              </p>

              <p className={classes.textQuestion}>
                {getValues("q25.question")}
              </p>


              <Controller
                as={
                  <RadioGroup>
                      <FormControlLabel value="The can labelled 1" control={<Radio />} label="The can labelled 1"/>
                      <FormControlLabel value="The can labelled 2" control={<Radio />} label="The can labelled 2"/>
                      <FormControlLabel value="The can you must only put water into" control={<Radio />} label="The can you must only put water into"/>
                      <FormControlLabel value="Both cans" control={<Radio />} label="Both cans"/>
                  </RadioGroup>
                }
                name="q25.answer"
                control={control}
                defaultValue=""
                className={classes.options}
                
              />
            </FormControl>
          )
        case 25:
          return (
            <FormControl 
              className={classes.form}>
                
              <p>
                You must make sure that your own work place is clean and tidy. Avoid accidents by keeping all passageways clear. Never leave tools or equipment on the floor, and always turn powered tools off when you are not using them. Always walk rather than run, even when you are in a hurry. Before moving machinery, you must check that all obstructions have been removed.
              </p>

              <p className={classes.textQuestion}>
                {getValues("q26.question")}
              </p>


              <Controller
                as={
                  <RadioGroup>
                      <FormControlLabel value="By moving machinery" control={<Radio />} label="By moving machinery"/>
                      <FormControlLabel value="By running in an emergency" control={<Radio />} label="By running in an emergency"/>
                      <FormControlLabel value="By keeping passageways clear" control={<Radio />} label="By keeping passageways clear"/>
                      <FormControlLabel value="By turning powered tools on before you use them" control={<Radio />} label="By turning powered tools on before you use them"/>
                  </RadioGroup>
                }
                name="q26.answer"
                control={control}
                defaultValue=""
                className={classes.options}
                
              />
            </FormControl>
          )
        case 26:
          return (
            <FormControl 
              className={classes.form}>
                
              <p>
                You must make sure that your own work place is clean and tidy. Avoid accidents by keeping all passageways clear. Never leave tools or equipment on the floor, and always turn powered tools off when you are not using them. Always walk rather than run, even when you are in a hurry. Before moving machinery, you must check that all obstructions have been removed.
              </p>

              <p className={classes.textQuestion}>
                {getValues("q27.question")}
              </p>


              <Controller
                as={
                  <RadioGroup>
                      <FormControlLabel value="Turn powered tools off" control={<Radio />} label="Turn powered tools off"/>
                      <FormControlLabel value="Check that all obstructions have been removed" control={<Radio />} label="Check that all obstructions have been removed"/>
                      <FormControlLabel value="Make sure that your place is clean and tidy" control={<Radio />} label="Make sure that your place is clean and tidy"/>
                      <FormControlLabel value="Walk rather than run" control={<Radio />} label="Walk rather than run"/>
                  </RadioGroup>
                }
                name="q27.answer"
                control={control}
                defaultValue=""
                className={classes.options}
                
              />
            </FormControl>
          )
        case 27:
          return (
            <FormControl 
              className={classes.form}>
                
              <p>
                You must make sure that your own work place is clean and tidy. Avoid accidents by keeping all passageways clear. Never leave tools or equipment on the floor, and always turn powered tools off when you are not using them. Always walk rather than run, even when you are in a hurry. Before moving machinery, you must check that all obstructions have been removed.
              </p>

              <p className={classes.textQuestion}>
                {getValues("q28.question")}
              </p>


              <Controller
                as={
                  <RadioGroup>
                      <FormControlLabel value="When the passageway is clear" control={<Radio />} label="When the passageway is clear"/>
                      <FormControlLabel value="When you are in a hurry" control={<Radio />} label="When you are in a hurry"/>
                      <FormControlLabel value="When all obstructions have been removed" control={<Radio />} label="When all obstructions have been removed"/>
                      <FormControlLabel value="Never" control={<Radio />} label="Never"/>
                  </RadioGroup>
                }
                name="q28.answer"
                control={control}
                defaultValue=""
                className={classes.options}
                
              />
            </FormControl>
          )
      default: 
        return <p>Error</p>   
      }
    }

    return (
      <div>

      <h1 style={{marginBottom: 16}}>
        Lowie Industrial Test
      </h1>
      <Divider style={{marginBottom: 16}} />
      {!isFinished && 
        <div>
          <Hidden mdDown>
            <div className={classes.card}>
              <div style={{display: "flex", marginBottom: 16}}>
                <div style={{flexGrow: 1}}>
                    <div style={{display: "flex"}}>
                      <div style={{flex: "0 0 100px"}}>Question {activeStep+1}/{steps.length}</div> 
                      <div className={classes.vl}></div>
                      <div>{activeStep < 19 ? "Complete the following question" : "Read the following statement and then answer the question below by selecting the correct answer"}</div>
                    </div>
                </div>
                <div>
                  <div>
                    <div style={{display: "flex", fontSize: 18}}>
                      {getTimerMinutes()}:{getTimerSeconds()}
                      <Timer style={{marginLeft: 8}}/>
                    </div>
                  </div>
                </div>
              </div>
              <Divider />
              <div style={{height: 400}} >
                  <div className={classes.instructions}>
                    {getStepContent(activeStep)}
                  </div>
              </div>

              <Divider />

              <div style={{marginTop: 8}}>
                
                  <div style={{display: "flex"}}>
                      <Button
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        className={classes.backButton}>
                        Back
                      </Button>
                      {activeStep === steps.length - 1 ? 
                        <div>
                          {loading ?
                            <Button variant="contained" color="primary" style={{width: 80}}>
                                <CircularProgress size={25} style={{color: "white"}} />
                            </Button>
                          :
                            <Button variant="contained" color="primary" style={{width: 80}} onClick={handleSubmit(onSubmit)} disabled={watch(getQuestion()) === ""}>
                              Finish
                            </Button> 
                          }

                          {error &&
                              <span style={{color: "red", marginLeft: 8}}>{error}</span>
                          }
                        </div>
                      : 
                        <Button variant="contained" color="primary" style={{width: 80}} onClick={handleNext} disabled={watch(getQuestion()) === ""}>
                          Next
                        </Button>
                      }
                    </div>
              </div>
            </div>

            <Stepper activeStep={activeStep} alternativeLabel className={classes.stepper}>
              {steps.map((label: string, i: number) => (
                <Step key={i}>
                  <StepLabel classes={{
                  iconContainer: classes.iconContainer
                }}> {label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Hidden>
          
          <Hidden lgUp>
            <div className={classes.card} style={{marginBottom: 64}}>
              <div style={{display: "flex", marginBottom: 16}}>
                <div style={{flexGrow: 1}}>
                    <div>{activeStep < 19 ? "Complete the following question" : "Read the following statement and then answer the question below by selecting the correct answer"}</div>
                </div>
                <div>
                  <div>
                    <div style={{display: "flex", fontSize: 18, marginLeft: 8}}>
                      {getTimerMinutes()}:{getTimerSeconds()}
                      <Timer style={{marginLeft: 8}}/>
                    </div>
                  </div>
                </div>
              </div>
              <Divider />
              <div style={{height: 528, marginTop: 16}}>
                {getStepContent(activeStep)}
              </div>

            </div>
                
            <MobileStepper steps={steps.length} activeStep={activeStep} variant="text"
              nextButton={
                <div>
                  {activeStep === steps.length - 1 ? 
                    <div>
                      {error &&
                          <span style={{color: "red", marginRight: 8}}>{error}</span>
                      }
                      {loading ?
                        <Button variant="contained" color="primary" style={{width: 80}}>
                            <CircularProgress size={25} style={{color: "white"}} />
                        </Button>
                      :
                        <Button variant="contained" color="primary" style={{width: 80}} onClick={handleSubmit(onSubmit)} disabled={watch(getQuestion()) === ""}>
                          Finish
                        </Button> 
                      }
                    </div>
                  : 
                    <Button variant="contained" color="primary" style={{width: 80}} onClick={handleNext} disabled={watch(getQuestion()) === ""}>
                      Next
                    </Button>
                  }
                </div>
              }
              backButton={
                <Button
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  className={classes.backButton}>
                  Back
              </Button>
              }/>
          </Hidden>
        </div>
      }
      {isFinished &&
        <div>
          <p>Congratulations you completed the test in {getTime()}</p>
        </div>
      }
      </div>
    )
}
export default IndustrialTest;



  