import React, { useEffect, useState } from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import {
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Select,
  MenuItem,
  FormControl,
  FormLabel,
  FormHelperText,
  Button,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Paper,
} from "@material-ui/core";
import { ExpandMore, Edit, Clear, Save } from "@material-ui/icons";
import { useForm, Controller } from "react-hook-form";
import { AdditionalInfo } from "../../CandidateTypes";
import { InsertDriveFile } from "@material-ui/icons";
import Dropzone from "react-dropzone";
import { API, Storage } from "aws-amplify";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-around",
      overflow: "hidden",
      backgroundColor: theme.palette.background.paper,
    },
    input: {
      marginBottom: theme.spacing(3),
      width: "100%",
    },
    section: {
      marginBottom: 16,
    },
    label: {
      marginBottom: 6,
    },
    editButton: {
      width: 100,
      marginRight: 8,
    },
    actionButton: {
      marginRight: 8,
      marginBottom: 8,
      width: 100,
    },
    dropzone: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingTop: 97,
      paddingBottom: 97,
      paddingLeft: 20,
      paddingRight: 20,
      borderWidth: 2,
      borderRadius: 2,
      borderColor: "#bdbdbd",
      borderStyle: "dashed",
      backgroundColor: "#eeeeee",
      color: "#757575",
      outline: "none",
      transition: "border .24s ease-in-out",
    },
    error: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingTop: 97,
      paddingBottom: 97,
      paddingLeft: 20,
      paddingRight: 20,
      borderWidth: 2,
      borderRadius: 2,
      borderColor: "red",
      borderStyle: "dashed",
      backgroundColor: "#eeeeee",
      color: "#757575",
      outline: "none",
      transition: "border .24s ease-in-out",
    },
  })
);

interface Props {
  data?: AdditionalInfo;
  candidate?: string;
  refreshCandidateData: () => void;
}

export default function AdditionalInfoCard({
  data,
  candidate,
  refreshCandidateData,
}: Props) {
  const classes = useStyles();

  const { handleSubmit, watch, control, errors, clearErrors, reset, setValue } =
    useForm<AdditionalInfo>({
      shouldUnregister: false,
    });

  const [readOnly, setReadOnly] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setAPIError] = useState<string>();
  const [uploadedCovidCertificate, setCovidCertificate] = useState<Array<File>>(
    []
  );

  const hasHealthIssues = watch("hasHealthIssues");

  useEffect(() => {
    if (data) {
      reset(data);
    }
  }, [data]);

  const onSave = (data: AdditionalInfo) => {
    if (candidate) {
      setLoading(true);
      const params = {
        body: {
          data: data,
          section: "additionalInfo",
        },
      };
      API.post("", `/candidates/${candidate}/profile/update`, params)
        .then(() => {
          setReadOnly(true);
          setLoading(false);
          refreshCandidateData();
        })
        .catch((error: any) => {
          console.log("ERROR updating candidate additional info", error);
          setLoading(false);
          setAPIError("Error: unable to update info");
        });
    }
  };

  const handleCovidCertificateDrop = (files: any) => {
    if (candidate) {
      var objKey = candidate + "_COVID_CERTIFICATE_" + files[0].name;

      console.log("FILE UPLOAD", objKey);

      setLoading(true);
      Storage.put(objKey, files[0])
        .then(() => {
          Storage.get(objKey, { level: "public", download: false })
            .then(() => {
              setValue("covidCertificateImage", objKey);
              clearErrors("covidCertificateImage");
              setCovidCertificate(files);
            })
            .catch((error: any) => {
              console.log("ERROR fetching from s3", error);
            });
        })
        .catch((error: any) => {
          console.log("ERROR uploading to s3", error);
        });
      setLoading(false);
    }
  };

  function calcSize(fileSize: number): string {
    if (fileSize < 1000000) {
      return `${Math.round(fileSize / Math.pow(10, 3))} KB`;
    }
    return `${(fileSize / Math.pow(10, 6)).toFixed(1)} MB`;
  }

  function getCovidCertificateDropzoneClass() {
    if (errors.covidCertificateImage) {
      return classes.error;
    } else {
      return classes.dropzone;
    }
  }

  return (
    <div>
      <Accordion style={{ marginTop: 32 }}>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <h4>Additional Information</h4>
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ width: "100%" }}>
            <div style={{ display: "flex" }}>
              <div style={{ flexGrow: 1 }}>
                <p style={{ marginBottom: 24 }}>
                  Complete additional information details.
                </p>
              </div>
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.editButton}
                  onClick={() => setReadOnly(false)}
                >
                  Edit
                  <Edit style={{ marginLeft: 4 }} fontSize="small" />
                </Button>
              </div>
            </div>

            <section className={classes.section}>
              <h5 style={{ marginBottom: 16 }}>
                Next of Kin/ Emergency Contact
              </h5>

              <FormControl
                error={Boolean(errors.emergencyContactName)}
                className={classes.input}
              >
                <FormLabel className={classes.label}>Full Name</FormLabel>

                <Controller
                  as={
                    <TextField
                      placeholder="Enter full name"
                      fullWidth
                      variant="filled"
                      error={errors.emergencyContactName ? true : false}
                      helperText={errors.emergencyContactName?.message}
                      disabled={readOnly}
                    />
                  }
                  name="emergencyContactName"
                  control={control}
                  defaultValue=""
                />
              </FormControl>

              <FormControl
                error={Boolean(errors.emergencyContactRelationship)}
                className={classes.input}
              >
                <FormLabel className={classes.label}>Relationship</FormLabel>

                <Controller
                  as={
                    <TextField
                      placeholder="Enter relationship"
                      fullWidth
                      variant="filled"
                      error={errors.emergencyContactRelationship ? true : false}
                      helperText={errors.emergencyContactRelationship?.message}
                      disabled={readOnly}
                    />
                  }
                  name="emergencyContactRelationship"
                  control={control}
                  defaultValue=""
                />
              </FormControl>

              <FormControl
                error={Boolean(errors.emergencyContactPhone)}
                className={classes.input}
              >
                <FormLabel className={classes.label}>Phone</FormLabel>

                <Controller
                  as={
                    <TextField
                      placeholder="Enter phone"
                      fullWidth
                      variant="filled"
                      error={errors.emergencyContactPhone ? true : false}
                      helperText={errors.emergencyContactPhone?.message}
                      disabled={readOnly}
                    />
                  }
                  name="emergencyContactPhone"
                  control={control}
                  defaultValue=""
                />
              </FormControl>

              <FormControl
                className={classes.input}
                error={Boolean(errors.emergencyContactMobile)}
              >
                <FormLabel className={classes.label}>Mobile</FormLabel>

                <Controller
                  render={({ onChange, value }) => (
                    <TextField
                      placeholder="Enter mobile number"
                      fullWidth
                      variant="filled"
                      size="small"
                      value={value}
                      onChange={(v) => {
                        onChange(v);
                        clearErrors("emergencyContactMobile");
                      }}
                      error={errors.emergencyContactMobile ? true : false}
                      helperText={errors.emergencyContactMobile?.message}
                      disabled={readOnly}
                    />
                  )}
                  name="emergencyContactMobile"
                  control={control}
                  defaultValue=""
                />
              </FormControl>
            </section>

            <section className={classes.section}>
              <h5 style={{ marginBottom: 16 }}>Health Information</h5>
              <FormControl
                error={Boolean(errors.hasHealthIssues)}
                className={classes.input}
              >
                <FormLabel className={classes.label}>
                  Do you have any health issues that may affect your ability to
                  work?
                </FormLabel>

                <Controller
                  render={({ onChange, value }) => (
                    <Select
                      variant="filled"
                      defaultValue=""
                      disabled={readOnly}
                      value={value}
                      onChange={(event) => {
                        let val = event.target.value as string;
                        onChange(val);
                        if (val === "No") {
                          clearErrors("healthIssues");
                        }
                      }}
                    >
                      <MenuItem value="">Select an option</MenuItem>
                      <MenuItem value="Yes">Yes</MenuItem>
                      <MenuItem value="No">No</MenuItem>
                    </Select>
                  )}
                  name="hasHealthIssues"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "This is required",
                  }}
                />
                <FormHelperText>
                  {errors.hasHealthIssues && errors.hasHealthIssues.message}
                </FormHelperText>
              </FormControl>

              {hasHealthIssues === "Yes" && (
                <FormControl
                  error={Boolean(errors.healthIssues)}
                  className={classes.input}
                >
                  <FormLabel className={classes.label}>
                    {" "}
                    Please state what the health issue(s) is/are:
                  </FormLabel>

                  <Controller
                    as={
                      <TextField
                        placeholder="Enter health issues"
                        fullWidth
                        variant="filled"
                        error={errors.healthIssues ? true : false}
                        helperText={errors.healthIssues?.message}
                        disabled={readOnly}
                      />
                    }
                    name="healthIssues"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "This is required",
                    }}
                  />
                </FormControl>
              )}

              <FormControl
                error={Boolean(errors.takingMedication)}
                className={classes.input}
              >
                <FormLabel className={classes.label}>
                  Are you currently taking any medication that could affect your
                  ability to work?
                </FormLabel>

                <Controller
                  render={({ onChange, value }) => (
                    <Select
                      variant="filled"
                      defaultValue=""
                      disabled={readOnly}
                      value={value}
                      onChange={(event) => {
                        let val = event.target.value as string;
                        onChange(val);
                        if (val === "No") {
                          clearErrors("medicationDetails");
                        }
                      }}
                    >
                      <MenuItem value="">Select an option</MenuItem>
                      <MenuItem value="Yes">Yes</MenuItem>
                      <MenuItem value="No">No</MenuItem>
                    </Select>
                  )}
                  name="takingMedication"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "This is required",
                  }}
                />
                <FormHelperText>
                  {errors.takingMedication && errors.takingMedication.message}
                </FormHelperText>
              </FormControl>

              {watch("takingMedication") === "Yes" && (
                <FormControl
                  error={Boolean(errors.medicationDetails)}
                  className={classes.input}
                >
                  <FormLabel className={classes.label}>
                    Please state what the medication(s) is/are:
                  </FormLabel>

                  <Controller
                    as={
                      <TextField
                        placeholder="Enter detail"
                        fullWidth
                        variant="filled"
                        error={errors.medicationDetails ? true : false}
                        helperText={errors.medicationDetails?.message}
                        disabled={readOnly}
                      />
                    }
                    name="medicationDetails"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "This is required",
                    }}
                  />
                </FormControl>
              )}

              {/* Covid19 */}
              {/* <FormControl
                                error={Boolean(errors.isCovid19Vaccinated)}
                                className={classes.input}>

                                <FormLabel className={classes.label}>Have you been vaccinated against Covid-19?</FormLabel>
                                <Controller
                                    as={
                                        <Select variant="filled"
                                            defaultValue=""
                                            disabled={readOnly}
                                            displayEmpty={true}>
                                                
                                            <MenuItem value="">Select an option</MenuItem>
                                            <MenuItem value="No">No</MenuItem>
                                            <MenuItem value="First dose">First dose</MenuItem>
                                            <MenuItem value="First and second dose">First and second dose</MenuItem>
                                            <MenuItem value="First, second and booster">First, second and booster</MenuItem>
                                        </Select>
                                    }
                                    name="isCovid19Vaccinated"
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                        required: "This is required"
                                    }}
                                />
                                <FormHelperText>
                                    {errors.isCovid19Vaccinated && errors.isCovid19Vaccinated.message}
                                </FormHelperText>
                            </FormControl> */}

              {/* <FormControl
                                error={Boolean(errors.hasContractedCovid19)}
                                className={classes.input}>

                                <FormLabel className={classes.label}>In the past 10 days have you been identified as a household contact?</FormLabel>
                                <Controller
                                    as={
                                        <Select variant="filled"
                                            defaultValue=""
                                            disabled={readOnly}
                                            displayEmpty={true}>
                                            <MenuItem value="">Select an option</MenuItem>
                                            <MenuItem value="No">No</MenuItem>
                                            <MenuItem value="Yes">Yes</MenuItem>

                                        </Select>
                                    }
                                    name="hasContractedCovid19"
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                        required: "This is required"
                                    }}
                                />
                                <FormHelperText>
                                    {errors.hasContractedCovid19 && errors.hasContractedCovid19.message}
                                </FormHelperText>
                            </FormControl> */}

              {/* {watch("hasContractedCovid19") === "Yes" &&
                                <FormControl
                                    error={Boolean(errors.isClearedToWork)}
                                    className={classes.input}>

                                    <FormLabel className={classes.label}>Have you been cleared by your GP to work?</FormLabel>
                                    <Controller
                                        as={
                                            <Select variant="filled"
                                                defaultValue=""
                                                disabled={readOnly}
                                                displayEmpty={true}>
                                                <MenuItem value="">Select an option</MenuItem>
                                                <MenuItem value="No">No</MenuItem>
                                                <MenuItem value="Yes">Yes</MenuItem>

                                            </Select>
                                        }
                                        name="isClearedToWork"
                                        control={control}
                                        defaultValue=""
                                        rules={{
                                            required: "This is required"
                                        }}
                                    />
                                    <FormHelperText>
                                        {errors.isClearedToWork && errors.isClearedToWork.message}
                                    </FormHelperText>
                                </FormControl>
                            }

                            {watch("isClearedToWork") === "No" &&
                                <p>
                                    Please follow all government guidelines around working and Covid-19 at <a target="_blank" href="https://covid19.govt.nz/business-and-money/businesses/">https://covid19.govt.nz/business-and-money/businesses/</a>

                                </p>
                            } */}

              {/* <FormControl
                                error={Boolean(errors.covidCertificateImage)}
                                className={classes.input}>

                                <FormLabel className={classes.label}>Upload your COVID-19 Certificate below.</FormLabel>
                                <Controller
                                    control={control}
                                    name="covidCertificateImage"
                                    defaultValue=""
                                    rules={{
                                        // required: hasDriversLicense
                                    }}
                                    render={() => (
                                        <div >

                                            <Dropzone onDrop={handleCovidCertificateDrop} multiple={false} disabled={readOnly}
                                                accept={[
                                                    'image/jpeg',
                                                    'image/png',
                                                    'application/pdf',
                                                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document']}>
                                                {({ getRootProps, getInputProps }) => (
                                                    <Paper
                                                        variant="outlined"
                                                        {...getRootProps({ className: getCovidCertificateDropzoneClass() })}
                                                    >
                                                        <input {...getInputProps()} name="covidCertificateImage" />
                                                        <p>Drag 'n' drop files here, or click to select files</p>
                                                    </Paper>
                                                )}
                                            </Dropzone>
                                            {uploadedCovidCertificate.length !== 0 &&
                                                <List>
                                                    {uploadedCovidCertificate.map((f: File, index: number) => (
                                                        <ListItem key={index}>
                                                            <ListItemIcon>
                                                                <InsertDriveFile />
                                                            </ListItemIcon>
                                                            <ListItemText primary={f.name} secondary={calcSize(f.size)} />
                                                        </ListItem>
                                                    ))}
                                                </List>
                                            }
                                        </div>
                                    )}
                                />
                                <FormHelperText>
                                    {errors.covidCertificateImage && "This is required"}
                                </FormHelperText>
                            </FormControl> */}
            </section>

            <section className={classes.section}>
              <h5 style={{ marginBottom: 16 }}>Other details</h5>

              <FormControl
                error={Boolean(errors.drugAlcoholTests)}
                className={classes.input}
              >
                <FormLabel className={classes.label}>
                  Would you be able to undertake work that requires regular drug
                  and alcohol tests on site?
                </FormLabel>

                <Controller
                  as={
                    <Select
                      variant="filled"
                      defaultValue=""
                      disabled={readOnly}
                    >
                      <MenuItem value="">Select an option</MenuItem>
                      <MenuItem value="Yes">Yes</MenuItem>
                      <MenuItem value="No">No</MenuItem>
                    </Select>
                  }
                  name="drugAlcoholTests"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "This is required",
                  }}
                />
                <FormHelperText>
                  {errors.drugAlcoholTests && errors.drugAlcoholTests.message}
                </FormHelperText>
              </FormControl>

              <FormControl
                error={Boolean(errors.transport)}
                className={classes.input}
              >
                <FormLabel className={classes.label}>
                  Do you have reliable transport?
                </FormLabel>

                <Controller
                  as={
                    <Select
                      variant="filled"
                      defaultValue=""
                      disabled={readOnly}
                    >
                      <MenuItem value="">Select an option</MenuItem>
                      <MenuItem value="Yes">Yes</MenuItem>
                      <MenuItem value="No">No</MenuItem>
                    </Select>
                  }
                  name="transport"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "This is required",
                  }}
                />
                <FormHelperText>
                  {errors.transport && errors.transport.message}
                </FormHelperText>
              </FormControl>

              <FormControl
                error={Boolean(errors.claimedCompensation)}
                className={classes.input}
              >
                <FormLabel className={classes.label}>
                  Have you claimed accident compensation in the last 12 months?
                </FormLabel>

                <Controller
                  as={
                    <Select
                      variant="filled"
                      defaultValue=""
                      disabled={readOnly}
                    >
                      <MenuItem value="">Select an option</MenuItem>
                      <MenuItem value="Yes">Yes</MenuItem>
                      <MenuItem value="No">No</MenuItem>
                    </Select>
                  }
                  name="claimedCompensation"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "This is required",
                  }}
                />
                <FormHelperText>
                  {errors.claimedCompensation &&
                    errors.claimedCompensation.message}
                </FormHelperText>
              </FormControl>

              <FormControl
                error={Boolean(errors.criminalConvictions)}
                className={classes.input}
              >
                <FormLabel className={classes.label}>
                  Do you have any past / pending criminal convictions?
                </FormLabel>

                <Controller
                  render={({ onChange, value }) => (
                    <Select
                      variant="filled"
                      defaultValue=""
                      disabled={readOnly}
                      value={value}
                      onChange={(event) => {
                        let val = event.target.value as string;
                        onChange(val);
                        if (val === "No") {
                          clearErrors("criminalConvictionDetail");
                        }
                      }}
                    >
                      <MenuItem value="">Select an option</MenuItem>
                      <MenuItem value="Yes">Yes</MenuItem>
                      <MenuItem value="No">No</MenuItem>
                    </Select>
                  )}
                  name="criminalConvictions"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "This is required",
                  }}
                />
                <FormHelperText>
                  {errors.criminalConvictions &&
                    errors.criminalConvictions.message}
                </FormHelperText>
              </FormControl>

              {watch("criminalConvictions") === "Yes" && (
                <FormControl
                  error={Boolean(errors.criminalConvictionDetail)}
                  className={classes.input}
                >
                  <FormLabel className={classes.label}>
                    Provide details on your convictions
                  </FormLabel>

                  <Controller
                    as={
                      <TextField
                        placeholder="Enter detail"
                        fullWidth
                        variant="filled"
                        error={errors.criminalConvictionDetail ? true : false}
                        helperText={errors.criminalConvictionDetail?.message}
                        disabled={readOnly}
                      />
                    }
                    name="criminalConvictionDetail"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "This is required",
                    }}
                  />
                </FormControl>
              )}
            </section>

            <div style={{ display: "flex" }}>
              <div style={{ flexGrow: 1 }} />
              {!readOnly && (
                <div>
                  {error && (
                    <span style={{ color: "red", marginRight: 8 }}>
                      {error}
                    </span>
                  )}
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.actionButton}
                    onClick={() => {
                      setReadOnly(true);
                      refreshCandidateData();
                      clearErrors();
                    }}
                  >
                    Cancel
                    <Clear style={{ marginLeft: 4 }} fontSize="small" />
                  </Button>
                  {loading ? (
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.actionButton}
                    >
                      <CircularProgress size={25} style={{ color: "white" }} />
                    </Button>
                  ) : (
                    <Button
                      onClick={handleSubmit(onSave)}
                      variant="contained"
                      color="primary"
                      className={classes.actionButton}
                    >
                      Save
                      <Save style={{ marginLeft: 4 }} fontSize="small" />
                    </Button>
                  )}
                </div>
              )}
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
