import React from 'react'
import {  Route, Routes } from 'react-router-dom';
import AMPInductionQuizComponent from '../documents/AMP/AMPInductionQuizComponent';
import { CandidateActionsInfo } from '../CandidateTypes';

interface Props {
    action?: CandidateActionsInfo;
    candidateId?: string;
}

export default function Forms({ action, candidateId }: Props) {

    // let { path } = useRouteMatch()

    return (
        <Routes>
            <Route path={`/am-induction-quiz`} element={<AMPInductionQuizComponent formData={action} candidateId={candidateId} />}>
                
            </Route>
            {/* <Route path="{path}" element={<Navigate to="/" />}>
                
            </Route> */}
        </Routes>
    )
}
