import React, { useEffect } from "react";
import "./App.css";
import { Container, createMuiTheme, MuiThemeProvider } from "@material-ui/core";
import { createBrowserHistory, createHashHistory } from "history";
import { HashRouter, Route, Routes, useLocation } from "react-router-dom";
import PrivateRoute from "./components/PrivateRoute";
import PublicRoute from "./components/PublicRoute";
import useAuth from "./hooks/useAuth";
import { MyAuth } from "./hooks/useAuth";
import Login from "./routes/authentication/Login";
import Portal from "./Portal";
import SignUp from "./routes/authentication/SignUp";
import ForgotPassword from "./routes/authentication/ForgotPassword";
import NewPassword from "./routes/authentication/NewPassword";
import VerifyUser from "./routes/authentication/VerifyUser";
import ProtectedRoute from "./components/ProtectedRoute";
import Apply from "./routes/Apply";
import { Hub } from "aws-amplify";
import ReactGA from "react-ga4";

function App() {
  const auth: MyAuth = useAuth();
  const history = createHashHistory();

  const myTheme = createMuiTheme({
    palette: {
      primary: {
        light: "#5fcc76",
        main: "#259a49",
        dark: "#006b1e",
        contrastText: "#fff",
      },
      secondary: {
        light: "#f73378",
        main: "#f50057",
        dark: "#ab003c",
        contrastText: "#fff",
      },
    },
  });

  // Track page views
  const trackPageView = (location) => {
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  };

  useEffect(() => {
    Hub.listen("auth", async ({ payload: { event, data } }) => {
      switch (event) {
        case "customOAuthState":
          const originalUrl = decodeURIComponent(data);
          localStorage.setItem("lowie-route-from", originalUrl);
          break;
      }
    });
    ReactGA.initialize("G-MRM9HVQ3E6");

    // Track page views
    // const trackPageView = (location) => {
    //   ReactGA.set({ page: location.pathname });
    //   ReactGA.pageview(location.pathname);
    // };

    // // Track the initial page load
    trackPageView(window.location);

    // Track page views on route changes
    const unlisten = history.listen((location) => {
      console.log(location);
      trackPageView(location);
    });

    return () => {
      unlisten();
    };
  }, []);

  return (
    <MuiThemeProvider theme={myTheme}>
      <HashRouter>
        <Container maxWidth="xl">
          <Routes>
            <Route path="/register" element={<SignUp auth={auth} />} />
            <Route
              path="/forgot-password"
              element={<ForgotPassword auth={auth} />}
            />
            <Route path="/new-password" element={<NewPassword auth={auth} />} />
            <Route path="/verify" element={<VerifyUser auth={auth} />} />
            <Route path="/" element={<Login auth={auth} />} />
            <Route
              path="/apply"
              element={
                <ProtectedRoute auth={auth}>
                  <Apply auth={auth} />
                </ProtectedRoute>
              }
            />
            <Route
              path="/portal/*"
              element={
                <PrivateRoute auth={auth}>
                  <Portal auth={auth} />
                </PrivateRoute>
              }
            />
          </Routes>
        </Container>
      </HashRouter>
    </MuiThemeProvider>
  );
}

export default App;
