import React, { useEffect, useState } from "react";
import { Stepper, Step, StepLabel, Hidden, Button } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import RegistrationProfilePart1 from "./RegistrationProfilePart1";
import RegistrationProfilePart2 from "./RegistrationProfilePart2";
import AdditionalInformationPart1 from "./AdditionalInformationPart1";
// import AdditionalInformationPart2 from './AdditionalInformationPart2';
import RightToWork from "./RightToWork";
import Qualifications from "./Qualifications";
import WorkHistoryPart1 from "./WorkHistoryPart1";
import WorkHistoryPart2 from "./WorkHistoryPart2";
import { Candidate } from "../../CandidateTypes";
import { useNavigate, useParams } from "react-router-dom";
import { userInfo } from "os";
import { Search } from "@material-ui/icons";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    header: {
      display: "flex",
      marginBottom: 16,
    },
    button: {
      marginRight: theme.spacing(1),
    },
    completed: {
      display: "inline-block",
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
  })
);

interface Props {
  candidate: Candidate | undefined;
}

interface ParamTypes extends Record<string, string | undefined> {
  step: string;
}

export default function RegistrationProcess({ candidate }: Props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true);

  const { step } = useParams<ParamTypes>();

  const navigate = useNavigate();

  useEffect(() => {
    if (candidate) {
      setActiveStep(candidate.registrationStep);
      window.history.replaceState(
        null,
        "",
        `#/portal/registration-process/${candidate.registrationStep + 1}`
      );
    }
  }, [candidate]);

  useEffect(() => {
    if (candidate) {
      if (step && !isInitialLoad) {
        let intStep = parseInt(step, 10);
        if (intStep <= candidate.registrationStep + 1) {
          if (candidate.registrationStep === 7) {
            window.history.replaceState(
              null,
              "",
              `#/portal/registration-process/${candidate.registrationStep + 1}`
            );
          } else if (intStep > 0 && intStep < 10) {
            setActiveStep(intStep - 1);
          } else {
            window.history.replaceState(
              null,
              "",
              `#/portal/registration-process/${candidate.registrationStep + 1}`
            );
          }
        } else {
          window.history.replaceState(
            null,
            "",
            `#/portal/registration-process/${candidate.registrationStep + 1}`
          );
        }
      } else {
        setIsInitialLoad(false);
        window.history.replaceState(
          null,
          "",
          `#/portal/registration-process/${candidate.registrationStep + 1}`
        );
      }
    } else {
      console.log(candidate);
    }
  }, [step, candidate]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  useEffect(() => {
    if (activeStep === 8) {
      localStorage.removeItem("personalInfoPart1Form");
      localStorage.removeItem("personalInfoPart2Form");
      localStorage.removeItem("additionalInfoPart1Form");
      localStorage.removeItem("additionalInfoPart2Form");
      localStorage.removeItem("rightToWorkForm");
      localStorage.removeItem("qualificationsForm");
      localStorage.removeItem("workHistory1Form");
      localStorage.removeItem("workHistory2Form");
    }
  }, [activeStep]);

  function incrementActiveStep() {
    if (candidate) {
      candidate.registrationStep = activeStep + 1;
      navigate(`/portal/registration-process/${activeStep + 2}`);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  }

  function incrementActiveStepTwice() {
    if (candidate) {
      candidate.registrationStep = activeStep + 2;
      navigate(`/portal/registration-process/${activeStep + 3}`);
      setActiveStep((prevActiveStep) => prevActiveStep + 2);
    }
  }

  function getStepContent(step: number): JSX.Element {
    switch (step) {
      case 0:
        return (
          <RegistrationProfilePart1
            candidate={candidate}
            activeStep={activeStep}
            incrementActiveStep={incrementActiveStep}
          />
        );
      case 1:
        return (
          <RegistrationProfilePart2
            candidate={candidate}
            activeStep={activeStep}
            incrementActiveStep={incrementActiveStep}
          />
        );
      case 2:
        return (
          <AdditionalInformationPart1
            candidate={candidate}
            activeStep={activeStep}
            incrementActiveStep={incrementActiveStep}
          />
        );
      // case 3:
      //     return <AdditionalInformationPart2
      //     candidate={candidate}
      //     activeStep={activeStep}
      //     incrementActiveStep={incrementActiveStep} />;
      case 3:
        return (
          <RightToWork
            candidate={candidate}
            activeStep={activeStep}
            incrementActiveStep={incrementActiveStep}
          />
        );
      case 4:
        return (
          <Qualifications
            candidate={candidate}
            activeStep={activeStep}
            incrementActiveStep={incrementActiveStep}
          />
        );
      case 5:
        return (
          <WorkHistoryPart1
            candidate={candidate}
            activeStep={activeStep}
            incrementActiveStep={incrementActiveStep}
            incrementActiveStepTwice={incrementActiveStepTwice}
          />
        );
      case 6:
        return (
          <WorkHistoryPart2
            candidate={candidate}
            activeStep={activeStep}
            incrementActiveStep={incrementActiveStep}
          />
        );
      case 7:
        return (
          <div>
            <h3 style={{ marginBottom: 16 }}>Complete</h3>
            <p>
              Congratulations! You are now part of team Lowie. Please have a
              look at our available roles while we complete your application.
            </p>
            <Button
              style={{ marginTop: 16 }}
              variant="contained"
              color="primary"
              onClick={() => navigate("/")}
            >
              Return home
            </Button>
            <Button
              style={{ marginTop: 16, marginLeft: 16 }}
              variant="contained"
              color="primary"
              onClick={() => navigate("/portal/jobs")}
            >
              <Search style={{ marginRight: 4 }} />
              Apply for Job
            </Button>
          </div>
        );
      default:
        return <div></div>;
    }
  }

  return (
    <div className="card">
      <Hidden xsDown>
        <div className={classes.header}>
          <h2>Registration Process</h2>
        </div>
        <Stepper activeStep={activeStep} alternativeLabel>
          {[...Array(8)].map((_, i: number) => (
            <Step key={i}>
              <StepLabel></StepLabel>
            </Step>
          ))}
        </Stepper>
        <div>
          <div>
            <div className={classes.instructions}>
              {getStepContent(activeStep)}
            </div>
          </div>
        </div>
      </Hidden>

      <Hidden smUp>
        <div className={classes.instructions}>{getStepContent(activeStep)}</div>
        {/* <MobileStepper steps={steps.length} activeStep={activeStep} variant="text"
                    nextButton={
                        <div>
                            {activeStep === steps.length - 1 ? 
                                <Button variant="contained" color="primary">
                                Finish
                                </Button> 
                            : 
                                <Button variant="contained" color="primary" onClick={handleNext}>
                                Next
                                </Button>
                            }
                        </div>
                    }
                    backButton={
                        <Button
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        className={classes.backButton}>
                        Back
                    </Button>
                }/> */}
      </Hidden>
    </div>
  );
}
