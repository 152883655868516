import React, { useState, useEffect } from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import {
  Select,
  MenuItem,
  Grid,
  TextField,
  Button,
  FormControl,
  FormLabel,
  FormHelperText,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import Dropzone from "react-dropzone";
import { InsertDriveFile } from "@material-ui/icons";
import { API, Storage } from "aws-amplify";
import { Candidate } from "../../CandidateTypes";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    input: {
      marginBottom: theme.spacing(3),
      width: "100%",
    },
    label: {
      marginBottom: 6,
    },
    header: {
      display: "flex",
      marginBottom: 16,
    },
    title: {
      flexGrow: 1,
    },
    uploadCard: {
      marginBottom: 40,
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    dropzone: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingTop: 97,
      paddingBottom: 97,
      paddingLeft: 20,
      paddingRight: 20,
      borderWidth: 2,
      borderRadius: 2,
      borderColor: "#bdbdbd",
      borderStyle: "dashed",
      backgroundColor: "#eeeeee",
      color: "#757575",
      outline: "none",
      transition: "border .24s ease-in-out",
    },
    error: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingTop: 97,
      paddingBottom: 97,
      paddingLeft: 20,
      paddingRight: 20,
      borderWidth: 2,
      borderRadius: 2,
      borderColor: "red",
      borderStyle: "dashed",
      backgroundColor: "#eeeeee",
      color: "#757575",
      outline: "none",
      transition: "border .24s ease-in-out",
    },
    disabled: {
      opacity: 0.4,
    },
  })
);

interface QualificationsForm {
  hasDriversLicense: string;
  driversLicenseImage: string;
  hasForkhoistLicense: string;
  forkhoistLicenseType: string;
  forkhoistLicenseImage: string;
  additionalLicenseImage: string;
  qualifications: string;
  qualificationsDocuments: string;
}

interface Props {
  candidate?: Candidate;
  activeStep: number;
  incrementActiveStep: () => void;
}

export default function Qualifications({
  candidate,
  activeStep,
  incrementActiveStep,
}: Props) {
  const classes = useStyles();

  const { handleSubmit, watch, control, errors, clearErrors, setValue, reset } =
    useForm<QualificationsForm>({
      defaultValues: {
        hasDriversLicense: "",
        driversLicenseImage: "",
        hasForkhoistLicense: "",
        forkhoistLicenseType: "",
        forkhoistLicenseImage: "",
        additionalLicenseImage: "",
        qualifications: "",
        qualificationsDocuments: "",
      },
    });

  const [isLoading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    let jsonForm = localStorage.getItem("qualificationsForm");
    if (jsonForm) {
      let form: QualificationsForm = JSON.parse(jsonForm);
      reset(form);
    }
  }, []);

  const hasDriversLicense = watch("hasDriversLicense");
  const hasForkhoistLicense = watch("hasForkhoistLicense");
  const [hasAdditionalLicenses, setHasAdditionalLicenses] =
    useState<boolean>(false);
  const [hasQualificationDocs, setHasQualificationDocs] =
    useState<boolean>(false);

  const [uploadedDriversLicense, setDriversLicense] = useState<Array<File>>([]);
  const [uploadedForkhoistLicense, setForkhoistLicense] = useState<Array<File>>(
    []
  );
  const [uploadedAdditionalLicense, setAdditionalLicense] = useState<
    Array<File>
  >([]);
  const [uploadeQualificationsDocs, setQualificationsDocs] = useState<
    Array<File>
  >([]);

  const handleNext = (data: QualificationsForm) => {
    setLoading(true);

    localStorage.setItem("qualificationsForm", JSON.stringify(data));

    const params = {
      body: {
        data: data,
        step: activeStep,
      },
    };

    API.post("", `/candidates/${candidate?.username}/registration`, params)
      .then(() => {
        setLoading(false);
        incrementActiveStep();
      })
      .catch((error: any) => {
        console.log("ERROR", error);
        setLoading(false);
      });
  };

  const handleSkip = () => {
    setLoading(true);

    const params = {
      body: {
        id: candidate?.username,
        step: activeStep,
      },
    };

    API.post("", `/candidates/registration/skip`, params)
      .then(() => {
        setLoading(false);
        incrementActiveStep();
      })
      .catch((error: any) => {
        console.log("ERROR: ", error);
        setLoading(false);
      });
  };

  function calcSize(fileSize: number): string {
    if (fileSize < 1000000) {
      return `${Math.round(fileSize / Math.pow(10, 3))} KB`;
    }
    return `${(fileSize / Math.pow(10, 6)).toFixed(1)} MB`;
  }

  const handleDriversLicenseDrop = (files: any) => {
    var objKey = candidate?.username + "_DRIVERSLICENSE_" + files[0].name;

    console.log("FILE UPLOAD", objKey);

    setLoading(true);
    Storage.put(objKey, files[0])
      .then(() => {
        Storage.get(objKey, { level: "public", download: false })
          .then(() => {
            setValue("driversLicenseImage", objKey);
            clearErrors("driversLicenseImage");
            setDriversLicense(files);
          })
          .catch((error: any) => {
            console.log("ERROR fetching from s3", error);
          });
      })
      .catch((error: any) => {
        console.log("ERROR uploading to s3", error);
      });
    setLoading(false);
  };

  function getDriversLicenseDropzoneClass() {
    if (errors.driversLicenseImage) {
      return classes.error;
    } else {
      return classes.dropzone;
    }
  }

  const handleForkhoistLicenseDrop = (files: any) => {
    var objKey = candidate?.username + "_FORKHOISTLICENSE_" + files[0].name;

    console.log("FILE UPLOAD", objKey);

    setLoading(true);
    Storage.put(objKey, files[0])
      .then(() => {
        Storage.get(objKey, { level: "public", download: false })
          .then(() => {
            setValue("forkhoistLicenseImage", objKey);
            clearErrors("forkhoistLicenseImage");
            setForkhoistLicense(files);
          })
          .catch((error: any) => {
            console.log("ERROR fetching from s3", error);
          });
      })
      .catch((error: any) => {
        console.log("ERROR uploading to s3", error);
      });
    setLoading(false);
  };

  function getForkhoistLicenseDropzoneClass() {
    if (errors.forkhoistLicenseImage) {
      return classes.error;
    } else {
      return classes.dropzone;
    }
  }

  const handleAdditionalLicenseDrop = (files: any) => {
    var objKey = candidate?.username + "_ADDITIONALICENSE_" + files[0].name;

    console.log("FILE UPLOAD", objKey);

    setLoading(true);
    Storage.put(objKey, files[0])
      .then(() => {
        Storage.get(objKey, { level: "public", download: false })
          .then(() => {
            setValue("additionalLicenseImage", objKey);
            clearErrors("additionalLicenseImage");
            setAdditionalLicense(files);
          })
          .catch((error: any) => {
            console.log("ERROR fetching from s3", error);
          });
      })
      .catch((error: any) => {
        console.log("ERROR uploading to s3", error);
      });
    setLoading(false);
  };

  const getAdditionalLicensesDropzoneClass = () => {
    if (errors.additionalLicenseImage) {
      return classes.error;
    } else {
      return classes.dropzone;
    }
  };

  const handleQualificationsDrop = (files: any) => {
    var objKey = candidate?.username + "_QUALIFICATIONDOCS_" + files[0].name;

    console.log("FILE UPLOAD", objKey);

    setLoading(true);
    Storage.put(objKey, files[0])
      .then(() => {
        Storage.get(objKey, { level: "public", download: false })
          .then(() => {
            setValue("qualificationsDocuments", objKey);
            clearErrors("qualificationsDocuments");
            setQualificationsDocs(files);
          })
          .catch((error: any) => {
            console.log("ERROR fetching from s3", error);
          });
      })
      .catch((error: any) => {
        console.log("ERROR uploading to s3", error);
      });
    setLoading(false);
  };

  const getQualificationsDropzoneClass = () => {
    if (errors.qualificationsDocuments) {
      return classes.error;
    } else {
      return classes.dropzone;
    }
  };
  // const handleHasDriversLicense = (event: React.ChangeEvent<{ value: unknown }>) => {
  //     setHasDriversLicense(event.target.value as string)
  // }

  // const handleHasForkliftLicense = (event: React.ChangeEvent<{ value: unknown }>) => {
  //     setHasForkliftLicense(event.target.value as string)
  // }

  return (
    <div>
      <div className={classes.header}>
        <h2>Qualifications, licenses and certificates</h2>
      </div>
      <p style={{ marginBottom: 24 }}>
        Enter your qualification and certificate details.
      </p>

      <h4 style={{ marginBottom: 8 }}>Driver's License</h4>

      <FormControl
        error={Boolean(errors.hasDriversLicense)}
        className={classes.input}
      >
        <FormLabel className={classes.label}>
          {" "}
          Do you have a driver's license?
        </FormLabel>

        <Controller
          as={
            <Select
              variant="filled"
              defaultValue={hasDriversLicense}
              displayEmpty={true}
            >
              <MenuItem value="">Select an option</MenuItem>
              <MenuItem value="Yes">Yes</MenuItem>
              <MenuItem value="No">No</MenuItem>
            </Select>
          }
          name="hasDriversLicense"
          control={control}
          defaultValue={hasDriversLicense}
          rules={{
            required: "This is required",
          }}
        />
        <FormHelperText>
          {errors.hasDriversLicense && errors.hasDriversLicense.message}
        </FormHelperText>
      </FormControl>

      {hasDriversLicense === "Yes" && (
        <Grid item className={classes.uploadCard} sm={12} lg={6}>
          <FormControl
            error={Boolean(errors.driversLicenseImage)}
            className={classes.input}
          >
            <FormLabel className={classes.label}>
              Upload Drivers License below.
            </FormLabel>
            <Controller
              control={control}
              name="driversLicenseImage"
              defaultValue=""
              rules={{
                required: hasDriversLicense,
              }}
              render={() => (
                <div>
                  <Dropzone
                    onDrop={handleDriversLicenseDrop}
                    multiple={false}
                    accept={[
                      "image/jpeg",
                      "image/png",
                      "application/pdf",
                      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                    ]}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <Paper
                        variant="outlined"
                        {...getRootProps({
                          className: getDriversLicenseDropzoneClass(),
                        })}
                      >
                        <input
                          {...getInputProps()}
                          name="driversLicenseImage"
                        />
                        <p>
                          Drag 'n' drop files here, or click to select files
                        </p>
                      </Paper>
                    )}
                  </Dropzone>
                  {uploadedDriversLicense.length !== 0 && (
                    <List>
                      {uploadedDriversLicense.map((f: File, index: number) => (
                        <ListItem key={index}>
                          <ListItemIcon>
                            <InsertDriveFile />
                          </ListItemIcon>
                          <ListItemText
                            primary={f.name}
                            secondary={calcSize(f.size)}
                          />
                        </ListItem>
                      ))}
                    </List>
                  )}
                </div>
              )}
            />
            <FormHelperText>
              {errors.driversLicenseImage && "This is required"}
            </FormHelperText>
          </FormControl>
        </Grid>
      )}

      {/* <h4 style={{ marginBottom: 8 }}>Forkhoist License</h4>

      <FormControl
        error={Boolean(errors.hasForkhoistLicense)}
        className={classes.input}
      >
        <FormLabel className={classes.label}>
          {" "}
          Do you have a forkhoist license?
        </FormLabel>

        <Controller
          as={
            <Select
              variant="filled"
              defaultValue={hasForkhoistLicense}
              displayEmpty={true}
            >
              <MenuItem value="">Select an option</MenuItem>
              <MenuItem value="Yes">Yes</MenuItem>
              <MenuItem value="No">No</MenuItem>
            </Select>
          }
          name="hasForkhoistLicense"
          control={control}
          defaultValue={hasForkhoistLicense}
          rules={{
            required: "This is required",
          }}
        />
        <FormHelperText>
          {errors.hasForkhoistLicense && errors.hasForkhoistLicense.message}
        </FormHelperText>
      </FormControl>

      {hasForkhoistLicense === "Yes" && (
        <div>
          <FormControl
            error={Boolean(errors.forkhoistLicenseType)}
            className={classes.input}
          >
            <FormLabel className={classes.label}>
              What type do you have?
            </FormLabel>

            <Controller
              as={
                <Select variant="filled" defaultValue="" displayEmpty={true}>
                  <MenuItem value="">Select an option</MenuItem>
                  <MenuItem value="Counter balance">Counter balance</MenuItem>
                  <MenuItem value="Reach">Reach</MenuItem>
                  <MenuItem value="Stock picker man up">
                    Stock picker/ man up
                  </MenuItem>
                  <MenuItem value="Pellet truck">Pellet truck</MenuItem>
                </Select>
              }
              name="forkhoistLicenseType"
              control={control}
              defaultValue=""
              rules={{
                required: "This is required",
              }}
            />
            <FormHelperText>
              {errors.forkhoistLicenseType &&
                errors.forkhoistLicenseType.message}
            </FormHelperText>
          </FormControl>

          <Grid item className={classes.uploadCard} sm={12} lg={6}>
            <FormControl
              error={Boolean(errors.forkhoistLicenseImage)}
              className={classes.input}
            >
              <FormLabel className={classes.label}>
                Upload Forkhoist License below.
              </FormLabel>
              <Controller
                control={control}
                name="forkhoistLicenseImage"
                defaultValue=""
                rules={{
                  required: hasForkhoistLicense,
                }}
                render={() => (
                  <div>
                    <Dropzone
                      onDrop={handleForkhoistLicenseDrop}
                      multiple={false}
                      accept={[
                        "image/jpeg",
                        "image/png",
                        "application/pdf",
                        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                      ]}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <Paper
                          variant="outlined"
                          {...getRootProps({
                            className: getForkhoistLicenseDropzoneClass(),
                          })}
                        >
                          <input
                            {...getInputProps()}
                            name="forkhoistLicenseImage"
                          />
                          <p>
                            Drag 'n' drop files here, or click to select files
                          </p>
                        </Paper>
                      )}
                    </Dropzone>
                    {uploadedForkhoistLicense.length !== 0 && (
                      <List>
                        {uploadedForkhoistLicense.map(
                          (f: File, index: number) => (
                            <ListItem key={index}>
                              <ListItemIcon>
                                <InsertDriveFile />
                              </ListItemIcon>
                              <ListItemText
                                primary={f.name}
                                secondary={calcSize(f.size)}
                              />
                            </ListItem>
                          )
                        )}
                      </List>
                    )}
                  </div>
                )}
              />
              <FormHelperText>
                {errors.forkhoistLicenseImage && "This is required"}
              </FormHelperText>
            </FormControl>
          </Grid>
        </div>
      )} */}

      <h4 style={{ marginBottom: 8 }}>Additional Licenses</h4>
      <Grid item className={classes.uploadCard} sm={12} lg={6}>
        <div>
          <FormControlLabel
            style={{ marginBottom: 8 }}
            control={
              <Checkbox
                id="checked"
                checked={hasAdditionalLicenses}
                onChange={() => {
                  if (hasAdditionalLicenses) {
                    clearErrors("additionalLicenseImage");
                  }
                  setHasAdditionalLicenses(!hasAdditionalLicenses);
                }}
              />
            }
            label="Upload Additional Licenses"
          />

          {hasAdditionalLicenses && (
            <FormControl
              error={Boolean(errors.additionalLicenseImage)}
              className={classes.input}
            >
              <FormLabel className={classes.label}>
                Upload Additional Licenses below.
              </FormLabel>
              <Controller
                control={control}
                name="additionalLicenseImage"
                defaultValue=""
                rules={{
                  required: hasAdditionalLicenses,
                }}
                render={() => (
                  <div>
                    <Dropzone
                      onDrop={handleAdditionalLicenseDrop}
                      multiple={false}
                      accept={[
                        "image/jpeg",
                        "image/png",
                        "application/pdf",
                        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                      ]}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <Paper
                          variant="outlined"
                          {...getRootProps({
                            className: getAdditionalLicensesDropzoneClass(),
                          })}
                        >
                          <input
                            {...getInputProps()}
                            name="additionalLicenseImage"
                          />
                          <p>
                            Drag 'n' drop files here, or click to select files
                          </p>
                        </Paper>
                      )}
                    </Dropzone>
                    {uploadedAdditionalLicense.length !== 0 && (
                      <List>
                        {uploadedAdditionalLicense.map(
                          (f: File, index: number) => (
                            <ListItem key={index}>
                              <ListItemIcon>
                                <InsertDriveFile />
                              </ListItemIcon>
                              <ListItemText
                                primary={f.name}
                                secondary={calcSize(f.size)}
                              />
                            </ListItem>
                          )
                        )}
                      </List>
                    )}
                  </div>
                )}
              />
              <FormHelperText>
                {errors.additionalLicenseImage && "This is required"}
              </FormHelperText>
            </FormControl>
          )}
        </div>
      </Grid>

      <h4 style={{ marginBottom: 8 }}>Qualifications and Certificates</h4>
      <FormControl className={classes.input}>
        <FormLabel className={classes.label}>
          Please add any additional qualifications or certificates you have.
        </FormLabel>

        <Controller
          as={
            <TextField
              placeholder="Enter qualifications"
              fullWidth
              variant="filled"
              multiline
              rows={3}
            />
          }
          name="qualifications"
          control={control}
          defaultValue=""
        />
      </FormControl>

      <Grid item className={classes.uploadCard} sm={12} lg={6}>
        <div>
          <FormControlLabel
            style={{ marginBottom: 8 }}
            control={
              <Checkbox
                id="checked"
                checked={hasQualificationDocs}
                onChange={() => {
                  if (hasQualificationDocs) {
                    clearErrors("qualificationsDocuments");
                  }
                  setHasQualificationDocs(!hasQualificationDocs);
                }}
              />
            }
            label="Upload Qualification Documents"
          />

          {hasQualificationDocs && (
            <FormControl
              error={Boolean(errors.qualificationsDocuments)}
              className={classes.input}
            >
              <FormLabel className={classes.label}>
                Upload Qualification Documents below.
              </FormLabel>
              <Controller
                control={control}
                name="qualificationsDocuments"
                defaultValue=""
                rules={{
                  required: hasQualificationDocs,
                }}
                render={() => (
                  <div>
                    <Dropzone
                      onDrop={handleQualificationsDrop}
                      multiple={false}
                      accept={[
                        "image/jpeg",
                        "image/png",
                        "application/pdf",
                        "application/vnd.openxmlformats-officedocument.wordprocessingm",
                      ]}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <Paper
                          variant="outlined"
                          {...getRootProps({
                            className: getQualificationsDropzoneClass(),
                          })}
                        >
                          <input
                            {...getInputProps()}
                            name="qualificationsDocuments"
                          />
                          <p>
                            Drag 'n' drop files here, or click to select files
                          </p>
                        </Paper>
                      )}
                    </Dropzone>
                    {uploadeQualificationsDocs.length !== 0 && (
                      <List>
                        {uploadeQualificationsDocs.map(
                          (f: File, index: number) => (
                            <ListItem key={index}>
                              <ListItemIcon>
                                <InsertDriveFile />
                              </ListItemIcon>
                              <ListItemText
                                primary={f.name}
                                secondary={calcSize(f.size)}
                              />
                            </ListItem>
                          )
                        )}
                      </List>
                    )}
                  </div>
                )}
              />
              <FormHelperText>
                {errors.qualificationsDocuments && "This is required"}
              </FormHelperText>
            </FormControl>
          )}
        </div>
      </Grid>

      <div>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleSkip()}
          disabled={isLoading}
        >
          Skip
        </Button>
        <Button
          variant="contained"
          color="primary"
          style={{ marginLeft: 8 }}
          onClick={handleSubmit(handleNext)}
          disabled={isLoading}
        >
          Next
        </Button>
      </div>
    </div>
  );
}
