import React, { useEffect, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import {
  Grid,
  Card,
  CardContent,
  Tooltip,
  Divider,
  LinearProgress,
  Button,
} from "@material-ui/core";
import {
  Domain,
  Person,
  CheckCircle,
  Warning,
  Assignment,
  EventNote,
  Search,
} from "@material-ui/icons";
import { CandidateActionsInfo } from "../CandidateTypes";
import { API } from "aws-amplify";
import { Route, Routes, useNavigate } from "react-router-dom";
import Forms from "./Forms";
import WelcomeDialog from "../components/WelcomeDialog";
import { MyAuth } from "../hooks/useAuth";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      [theme.breakpoints.up("sm")]: {
        padding: 8,
      },
    },
    card: {
      minHeight: 175,
      padding: theme.spacing(2),
      color: theme.palette.text.secondary,
    },
    control: {
      padding: theme.spacing(2),
    },
    cardButton: {
      marginLeft: "auto",
    },
    incompleteIcon: {
      color: "#ff9800",
      float: "right",
    },
    completeIcon: {
      color: "#4caf50",
      float: "right",
    },
    completeCard: {
      borderTop: "solid #4caf50 4px",
    },
    incompleteCard: {
      borderTop: "solid #ff9800 4px",
    },
    progress: {
      backgroundColor: "#ff9800",
    },
    progressBackground: {
      backgroundColor: "#ffe0b2",
    },
    applyButton: {
      backgroundColor: "#259a49",
      color: "#ffffff",
      "&:hover": {
        backgroundColor: "#006b1e",
      },
    },
  })
);

interface Props {
  auth: MyAuth;
}

const Home = ({ auth }: Props) => {
  const classes = useStyles();

  const [loading, setLoading] = useState<boolean>(false);

  const [registrationComplete, setRegistrationComplete] =
    useState<boolean>(false);
  const [actions, setActions] = useState<Array<CandidateActionsInfo>>([]);
  const [selectedForm, setSelectedForm] = useState<CandidateActionsInfo>();

  // let { path } = useRouteMatch()
  const navigate = useNavigate();

  useEffect(() => {
    if (auth.candidate) {
      setLoading(true);
      API.get("", `/candidates/${auth.candidate.username}/home`, {})
        .then((response: Array<CandidateActionsInfo>) => {
          if (response) {
            if ((auth.candidate?.registrationStep ?? 0) >= 7) {
              setRegistrationComplete(true);
            }
            setActions(response);
          }
          setLoading(false);
        })
        .catch((error: any) => {
          console.log("Error fetching home data", error);
          setLoading(false);
        });
    }
  }, [auth.candidate]);

  const getFormIcon = (name: string): JSX.Element => {
    switch (name) {
      case "Industrial Test":
        return <Domain />;
      case "Job Placement":
        return <Assignment />;
      default:
        return <EventNote />;
    }
  };

  return (
    <Routes>
      <Route
        path="/"
        element={
          <div className={classes.root}>
            <div>
              <h1 style={{ marginBottom: 16 }}>Home</h1>
              {loading && (
                <LinearProgress
                  className={classes.progressBackground}
                  classes={{ barColorPrimary: classes.progress }}
                />
              )}
              <Divider />
            </div>

            {!loading &&
              registrationComplete &&
              (actions.length === 0 ||
                actions.every((a: CandidateActionsInfo) => a.completed)) && (
                <Grid
                  container
                  spacing={3}
                  style={{ marginTop: 24, marginBottom: 64 }}
                >
                  <Grid item xs={12}>
                    <Card className={`${classes.card} ${classes.completeCard}`}>
                      <CardContent>
                        <div style={{ display: "flex" }}>
                          <CheckCircle
                            fontSize="large"
                            style={{ color: "#259a49" }}
                          />
                          <h4 style={{ marginLeft: 8, marginTop: 4 }}>
                            All Actions Completed
                          </h4>
                        </div>
                        <div style={{ marginTop: 16 }}>
                          <p>
                            There are no more actions to complete. Apply for
                            Jobs!
                          </p>
                        </div>
                      </CardContent>
                      <div style={{ display: "flex" }}>
                        <div style={{ flexGrow: 1 }}></div>
                        <Button
                          variant="contained"
                          className={`${classes.cardButton} ${classes.applyButton}`}
                          href="#/portal/jobs"
                        >
                          <Search style={{ marginRight: 4 }} />
                          Apply for Jobs
                        </Button>
                      </div>
                    </Card>
                  </Grid>
                </Grid>
              )}

            {!loading && (
              <div>
                {registrationComplete ? (
                  <Grid
                    container
                    spacing={3}
                    style={{ marginTop: 24, marginBottom: 64 }}
                  >
                    {actions.length !== 0 &&
                      actions.map((a: CandidateActionsInfo, i: number) => (
                        <React.Fragment key={i}>
                          {!a.completed && (
                            <Grid item xs={12} lg={6} key={a.label}>
                              <Card
                                className={`${classes.card} ${classes.incompleteCard}`}
                              >
                                <Tooltip title="Required">
                                  <Warning
                                    className={classes.incompleteIcon}
                                    fontSize="small"
                                  />
                                </Tooltip>
                                <CardContent>
                                  <div style={{ display: "flex" }}>
                                    {getFormIcon(a.label)}
                                    <h4 style={{ marginLeft: 8 }}>{a.label}</h4>
                                  </div>
                                  <div style={{ marginTop: 16 }}>
                                    <p>{a.description}</p>
                                  </div>
                                </CardContent>
                                <div style={{ display: "flex" }}>
                                  <div style={{ flexGrow: 1 }}></div>
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.cardButton}
                                    onClick={() => {
                                      navigate(`${a.route}`);
                                      setSelectedForm(a);
                                    }}
                                  >
                                    {`Complete ${a.label}`}
                                  </Button>
                                </div>
                              </Card>
                            </Grid>
                          )}
                        </React.Fragment>
                      ))}
                  </Grid>
                ) : (
                  <Grid
                    container
                    spacing={3}
                    style={{ marginTop: 24, marginBottom: 64 }}
                  >
                    <Grid item xs={12} lg={6}>
                      <Card
                        className={`${classes.card} ${classes.incompleteCard}`}
                      >
                        <Tooltip title="Required">
                          <Warning
                            className={classes.incompleteIcon}
                            fontSize="small"
                          />
                        </Tooltip>
                        <CardContent>
                          <div style={{ display: "flex" }}>
                            <Person />
                            <h4 style={{ marginLeft: 8 }}>
                              Profile Information
                            </h4>
                          </div>
                          <div style={{ marginTop: 16 }}>
                            <p>Complete your profile information here.</p>
                          </div>
                        </CardContent>
                        <div style={{ display: "flex" }}>
                          <div style={{ flexGrow: 1 }}></div>
                          <Button
                            variant="contained"
                            color="primary"
                            className={classes.cardButton}
                            href="#/portal/registration-process/1"
                          >
                            Complete Profile
                          </Button>
                        </div>
                      </Card>
                    </Grid>
                  </Grid>
                )}
              </div>
            )}
          </div>
        }
      ></Route>
      <Route
        path="/forms"
        element={
          <Forms action={selectedForm} candidateId={auth.candidate?.pk} />
        }
      ></Route>
    </Routes>
  );
};
export default Home;
