import React, { useState } from "react";
import {
  TextField, Select, MenuItem, Checkbox, FormControlLabel, FormGroup, Grid,
  Button, FormControl, FormHelperText, FormLabel, RadioGroup, Radio
} from "@material-ui/core"
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { useForm, Controller } from "react-hook-form";
import { API } from "aws-amplify";
import { Candidate } from '../../CandidateTypes';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiTextField-root': {
        marginBottom: theme.spacing(3),
        width: "100%"
      },
      '& .MuiInput-root': {
        marginBottom: theme.spacing(3),
        width: "100%"
      },
      '& .MuiFormGroup-root':{
        marginBottom: theme.spacing(3)
      }
    },
    submit: {
      marginTop: 32,
      width: 100
    },
    select: {
      marginBottom: theme.spacing(3),
      width: "100%"
    },
    input: {
      marginBottom: theme.spacing(3),
      width: "100%"
    },
    label: {
        marginBottom: 6
    },
  }),
);

interface Props {
  // incrementActiveStep: () => void;
  candidate: Candidate | undefined,
  // activeStep: number
}

interface KiwiSaverForm {
  isKiwiSaver: string;
  kiwiSaverPercentage: string;
  // bankNumber: string;
  // branch: string;
  // accountName: string;
  fullName: string;
  signature: string;
  date: string;
  irdNumber:string
}

const KiwiSaver = ({ candidate }: Props) =>{

  const classes = useStyles()

  const { handleSubmit, control, errors, watch } = useForm<KiwiSaverForm>();
  const [isLoading, setLoading] = useState<boolean>(false)

  const onSubmit = (data: KiwiSaverForm) => {
    if(candidate) {
      setLoading(true);

      const params = {
          body:{
            data: data,
            // step: activeStep,
            id: candidate.username
          }
      };
      API.post("", `/candidates/jobPlacement/update`,params)
      .then(() => {
          setLoading(false)
          // incrementActiveStep();
      })
      .catch((error: any) => {
          console.log("ERROR updating ird form for candidate", error);
          setLoading(false);
      })
     
  }
}

  return (
    <div>
      <h3>
        Kiwi Saver Details - New Zealand
      </h3>	
      <p style={{marginBottom: 16}}>
        Please ensure that all details are correct as this is the information used to process your pay
      </p>

      <h4>
        KiwiSaver
      </h4>

      <FormControl
        error={Boolean(errors.isKiwiSaver)} 
        className={classes.input}>

        <FormLabel className={classes.label}>Are you a KiwiSaver Member</FormLabel>

        <Controller
            as={
                <RadioGroup>
                  <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="No" control={<Radio />} label="No" />
                </RadioGroup>
            }
            name="isKiwiSaver"
            control={control}
            defaultValue=""
            rules={{
                required: "This is required"
            }}  
        />
        <FormHelperText>
            {errors.isKiwiSaver && errors.isKiwiSaver.message}
        </FormHelperText>
      </FormControl>

      {watch("isKiwiSaver") === "Yes" &&
        <FormControl
          error={Boolean(errors.kiwiSaverPercentage)} 
          className={classes.input}>

          <FormLabel className={classes.label}>Please advise the percentage to deduct</FormLabel>

          <Controller
              as={
                  <RadioGroup>
                    <FormControlLabel value="3%" control={<Radio />} label="3%" />
                    <FormControlLabel value="4%" control={<Radio />} label="4%" />
                    <FormControlLabel value="6%" control={<Radio />} label="6%" />
                    <FormControlLabel value="8%" control={<Radio />} label="8%" />
                    <FormControlLabel value="10%" control={<Radio />} label="10%" />
                    
                  </RadioGroup>
              }
              name="kiwiSaverPercentage"
              control={control}
              defaultValue=""
              rules={{
                  required: "This is required"
              }}  
          />
          <FormHelperText>
              {errors.kiwiSaverPercentage && errors.kiwiSaverPercentage.message}
          </FormHelperText>
        </FormControl>
      }

        <FormControl
          error={Boolean(errors.irdNumber)} 
          className={classes.input}>

          <FormLabel className={classes.label}>IRD Number</FormLabel>

          <Controller
              as={
                  <TextField
                      placeholder="Enter IRD number"
                      fullWidth
                      variant="filled"
                      error={errors.irdNumber ? true : false}
                      helperText={errors.irdNumber?.message || "9 digit number - If you don’t know your IRD number or you don’t have one, call 0800 549 472"}/>
              }
              name="irdNumber"
              control={control}
              defaultValue=""

              rules={{
                required: "This is required",
                pattern: /\d{9}/,
                maxLength:9
              }}
          />
        </FormControl>
 
      {/* <h3 style={{marginBottom: 16}}>
          Bank Account				
      </h3>

      <FormControl
        error={Boolean(errors.bankNumber)} 
        className={classes.input}>

        <FormLabel className={classes.label}>Bank Number</FormLabel>

        <Controller
            as={
                <TextField
                    placeholder="Enter bank number"
                    fullWidth
                    variant="filled"
                    error={errors.bankNumber ? true : false}
                    helperText={errors.bankNumber?.message}/>
            }
            name="bankNumber"
            control={control}
            defaultValue=""
            rules={{
              required: "This is required"
            }}
        />
      </FormControl>

      <FormControl
        error={Boolean(errors.branch)} 
        className={classes.input}>

        <FormLabel className={classes.label}>Branch</FormLabel>

        <Controller
            as={
                <TextField
                    placeholder="Enter branch"
                    fullWidth
                    variant="filled"
                    error={errors.branch ? true : false}
                    helperText={errors.branch?.message}/>
            }
            name="branch"
            control={control}
            defaultValue=""
            rules={{
              required: "This is required"
            }}
        />
      </FormControl>

      <FormControl
        error={Boolean(errors.accountName)} 
        className={classes.input}>

        <FormLabel className={classes.label}>Account Name</FormLabel>

        <Controller
            as={
                <TextField
                    placeholder="Enter account name"
                    fullWidth
                    variant="filled"
                    error={errors.accountName ? true : false}
                    helperText={errors.accountName?.message}/>
            }
            name="accountName"
            control={control}
            defaultValue=""
            rules={{
              required: "This is required"
            }}
        />
      </FormControl> */}

      <h3>
        Candidate Decleration
      </h3>
      <p style={{marginBottom: 16}}>
        I declare that the information provided above is complete and accurate and I understand that Lowie Recruitment takes no responsibility for wages that have been deposited into an incorrect account based on the information provided by me.
      </p>

      <FormControl
        error={Boolean(errors.fullName)} 
        className={classes.input}>

        <FormLabel className={classes.label}>Full Name</FormLabel>

        <Controller
            as={
                <TextField
                    placeholder="Enter full name"
                    fullWidth
                    variant="filled"
                    error={errors.fullName ? true : false}
                    helperText={errors.fullName?.message}/>
            }
            name="fullName"
            control={control}
            defaultValue=""
            rules={{
              required: "This is required"
            }}
        />
      </FormControl>

      <FormControl
        error={Boolean(errors.signature)} 
        className={classes.input}>

        <FormLabel className={classes.label}>Signature</FormLabel>

        <Controller
            as={
                <TextField
                    placeholder="Enter signature"
                    fullWidth
                    variant="filled"
                    error={errors.signature ? true : false}
                    helperText={errors.signature?.message}/>
            }
            name="signature"
            control={control}
            defaultValue=""
            rules={{
              required: "This is required"
            }}
        />
      </FormControl>

      <FormControl
          error={Boolean(errors.date)} 
          className={classes.input}>

          <FormLabel className={classes.label}>Date</FormLabel>

          <Controller
              render={({onChange, value}) => (
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Grid container>
                          <KeyboardDatePicker
                              autoOk
                              variant="inline"
                              format="dd/MM/yyyy"
                              margin="normal"
                              id="date-picker5"
                              value={value}
                              onChange={date => onChange(date)} />
                      </Grid>
                  </MuiPickersUtilsProvider>
              )}
              name="date"
              control={control}
              defaultValue={null}
              rules={{
                  required: "This is required"
              }}
          />
          <FormHelperText>
              {errors.date && errors.date.message}
          </FormHelperText>
      </FormControl>

      <div>
        <Button variant="contained" color="primary" onClick={handleSubmit(onSubmit)} disabled={isLoading}>
            Submit
        </Button>
    </div>
        
    </div>      
  )
}
export default KiwiSaver;


  