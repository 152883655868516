import React, { useState } from "react";
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import "../../App.css";
import {
    Button, Card, FormControl,
    FormLabel, TextField
} from "@material-ui/core";
import {
  useNavigate
} from "react-router-dom";
import { MyAuth } from "../../hooks/useAuth";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      marginBottom: theme.spacing(3),
      width: "100%"
    },
    label: {
        marginBottom: 6
    },
    error: {
      '&.MuiFormHelperText-contained': {
        marginLeft: 0
      }
    },
    submit: {
      marginTop: theme.spacing(3),
      width: 100
    },
  }),
);

interface Props {
    auth: MyAuth
}

export default function ForgotPassword({ auth }: Props) {

    const classes = useStyles()
    const navigate = useNavigate()

    const [userEmail, setUserEmail] = useState<string>()
    const [forgotPasswordError, setForgotPasswordError] = useState<string>()

    return (
        <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh"
            }}>
            <Card style={{padding: 16, width: 650}}>
                <h3 style={{marginBottom: 32}}>Forgot Password</h3>

                <p style={{marginBottom: 16}}>
                    Enter your account email address so we can send you a verification code to reset your password.
                </p>
                
                <FormControl 
                    className={classes.input}>
        
                    <FormLabel className={classes.label}>Email</FormLabel>

                    <TextField
                        placeholder="Enter your email"
                        fullWidth
                        variant="filled"
                        size="small"
                        value={userEmail}
                        onChange={(event) => {
                            let newVal = event.target.value
                            setUserEmail(newVal)
                        }}
                        FormHelperTextProps={{className: classes.error}}/>
                </FormControl>

                <div style={{display: "flex"}}>
                    <div style={{flexGrow: 1}}>
                        {forgotPasswordError &&
                            <span style={{color: "red", marginRight: 8}}>{forgotPasswordError}</span>
                        }
                    </div>
                    <div>
                        <Button style={{width: 120}} variant="contained" color="primary" onClick={() => {
                        if(userEmail) {
                            auth.forgotPassword(userEmail)
                            .then(() => {
                                navigate( "/new-password", {state: { userEmail: userEmail } })
                            })
                            .catch((error: any) => {
                                setForgotPasswordError(error.message)
                            })
                        }
                        }}>
                            Send Code
                        </Button>
                    </div>
                </div>
            </Card>
        </div>
    )
}
