import React, { useEffect } from 'react'
// import { Redirect, Route } from 'react-router'
import { Navigate } from 'react-router-dom';
import { MyAuth } from '../hooks/useAuth';

interface Props {
    children: React.ReactNode
    auth: MyAuth
    // path: string
}


export default function ProtectedRoute({ children, auth}: Props) {

    useEffect(() => {
        if(window) {
            window.scrollTo(0, 0)
        }
    }, [window])

    return (
        (auth.isSignedIn && auth.isCandidateSignedIn) ?
        <>
            {children}
        </>
    :
        // <Redirect to={{ pathname: "/" }} />
        <Navigate to="/" replace />
    )
}
