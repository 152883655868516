import React, { useEffect } from 'react'
// import { Redirect, Route } from 'react-router'
import Menu from "./AppMenu";
import { MyAuth } from '../hooks/useAuth';
import { Navigate } from 'react-router-dom';

interface Props {
    children: React.ReactNode
    auth: MyAuth
    // path: string
}

export default function PrivateRoute({ children, auth}: Props) {

    useEffect(() => {
        if(window) {
            window.scrollTo(0, 0)
        }
    }, [window])

    return (
        (auth.isSignedIn && auth.isCandidateSignedIn) ?
        <Menu candidate={auth.candidate} auth={auth}>
            {children}
        </Menu>
    :
        <Navigate to="/" replace />
    )
}
