import React, { useState, useEffect } from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import {
  Select,
  MenuItem,
  TextField,
  Button,
  FormControl,
  FormLabel,
  FormHelperText,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import { AdditionalInfoPart1, Candidate } from "../../CandidateTypes";
import { InsertDriveFile } from "@material-ui/icons";
import Dropzone from "react-dropzone";
import { API, Storage } from "aws-amplify";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    input: {
      marginBottom: theme.spacing(3),
      width: "100%",
    },
    label: {
      marginBottom: 6,
    },
    header: {
      display: "flex",
      marginBottom: 16,
    },
    title: {
      flexGrow: 1,
    },
    uploadCard: {
      marginBottom: 40,
    },
    section: {
      marginBottom: 16,
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    dropzone: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingTop: 97,
      paddingBottom: 97,
      paddingLeft: 20,
      paddingRight: 20,
      borderWidth: 2,
      borderRadius: 2,
      borderColor: "#bdbdbd",
      borderStyle: "dashed",
      backgroundColor: "#eeeeee",
      color: "#757575",
      outline: "none",
      transition: "border .24s ease-in-out",
    },
    error: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingTop: 97,
      paddingBottom: 97,
      paddingLeft: 20,
      paddingRight: 20,
      borderWidth: 2,
      borderRadius: 2,
      borderColor: "red",
      borderStyle: "dashed",
      backgroundColor: "#eeeeee",
      color: "#757575",
      outline: "none",
      transition: "border .24s ease-in-out",
    },
  })
);

interface Props {
  activeStep: number;
  incrementActiveStep: () => void;
  candidate?: Candidate;
}

const AdditionalInformationPart1 = ({
  candidate,
  activeStep,
  incrementActiveStep,
}: Props) => {
  const classes = useStyles();

  const { handleSubmit, watch, control, errors, reset, setValue, clearErrors } =
    useForm<AdditionalInfoPart1>();
  const hasHealthIssues = watch("hasHealthIssues");
  const [isLoading, setLoading] = useState<boolean>(false);
  const [uploadedCovidCertificate, setCovidCertificate] = useState<Array<File>>(
    []
  );

  useEffect(() => {
    let jsonForm = localStorage.getItem("additionalInfoPart1Form");
    if (jsonForm) {
      let form: AdditionalInfoPart1 = JSON.parse(jsonForm);
      reset(form);
    }
  }, []);

  const handleNext = (data: AdditionalInfoPart1) => {
    setLoading(true);

    localStorage.setItem("additionalInfoPart1Form", JSON.stringify(data));

    const params = {
      body: {
        data: data,
        step: activeStep,
      },
    };

    API.post("", `/candidates/${candidate?.username}/registration`, params)
      .then(() => {
        setLoading(false);
        incrementActiveStep();
      })
      .catch((error: any) => {
        console.log("ERROR", error);
        setLoading(false);
      });
  };

  const handleSkip = () => {
    setLoading(true);

    const params = {
      body: {
        id: candidate?.username,
        step: activeStep,
      },
    };

    API.post("", `/candidates/registration/skip`, params)
      .then(() => {
        setLoading(false);
        incrementActiveStep();
      })
      .catch((error: any) => {
        console.log("ERROR: ", error);
        setLoading(false);
      });
  };

  const handleCovidCertificateDrop = (files: any) => {
    if (candidate?.pk) {
      var objKey = candidate?.pk + "_COVID_CERTIFICATE_" + files[0].name;

      console.log("FILE UPLOAD", objKey);

      setLoading(true);
      Storage.put(objKey, files[0])
        .then(() => {
          Storage.get(objKey, { level: "public", download: false })
            .then(() => {
              setValue("covidCertificateImage", objKey);
              clearErrors("covidCertificateImage");
              setCovidCertificate(files);
            })
            .catch((error: any) => {
              console.log("ERROR fetching from s3", error);
            });
        })
        .catch((error: any) => {
          console.log("ERROR uploading to s3", error);
        });
      setLoading(false);
    }
  };

  function calcSize(fileSize: number): string {
    if (fileSize < 1000000) {
      return `${Math.round(fileSize / Math.pow(10, 3))} KB`;
    }
    return `${(fileSize / Math.pow(10, 6)).toFixed(1)} MB`;
  }

  function getCovidCertificateDropzoneClass() {
    if (errors.covidCertificateImage) {
      return classes.error;
    } else {
      return classes.dropzone;
    }
  }

  return (
    <div>
      <div className={classes.header}>
        <h2>Health and Safety</h2>
      </div>

      <p style={{ marginBottom: 24 }}>Complete health and safety details.</p>

      <section className={classes.section}>
        <h3 style={{ marginBottom: 16 }}>Health Information</h3>
        <FormControl
          error={Boolean(errors.hasHealthIssues)}
          className={classes.input}
        >
          <FormLabel className={classes.label}>
            Do you have any health issues that may affect your ability to work?
          </FormLabel>

          <Controller
            as={
              <Select variant="filled" defaultValue="" displayEmpty={true}>
                <MenuItem value="">Select an option</MenuItem>
                <MenuItem value="Yes">Yes</MenuItem>
                <MenuItem value="No">No</MenuItem>
              </Select>
            }
            name="hasHealthIssues"
            control={control}
            defaultValue=""
            rules={{
              required: "This is required",
            }}
          />
          <FormHelperText>
            {errors.hasHealthIssues && errors.hasHealthIssues.message}
          </FormHelperText>
        </FormControl>

        {hasHealthIssues === "Yes" && (
          <FormControl
            error={Boolean(errors.healthIssues)}
            className={classes.input}
          >
            <FormLabel className={classes.label}>
              {" "}
              Please state what the health issue(s) is/are:
            </FormLabel>

            <Controller
              as={
                <TextField
                  placeholder="Enter health issues"
                  fullWidth
                  variant="filled"
                  error={errors.healthIssues ? true : false}
                  helperText={errors.healthIssues?.message}
                />
              }
              name="healthIssues"
              control={control}
              defaultValue=""
              rules={{
                required: "This is required",
              }}
            />
          </FormControl>
        )}

        <FormControl
          error={Boolean(errors.takingMedication)}
          className={classes.input}
        >
          <FormLabel className={classes.label}>
            Are you currently taking any medication that could affect your
            ability to work?
          </FormLabel>

          <Controller
            as={
              <Select variant="filled" defaultValue="" displayEmpty={true}>
                <MenuItem value="">Select an option</MenuItem>
                <MenuItem value="Yes">Yes</MenuItem>
                <MenuItem value="No">No</MenuItem>
              </Select>
            }
            name="takingMedication"
            control={control}
            defaultValue=""
            rules={{
              required: "This is required",
            }}
          />
          <FormHelperText>
            {errors.takingMedication && errors.takingMedication.message}
          </FormHelperText>
        </FormControl>

        {watch("takingMedication") === "Yes" && (
          <FormControl
            error={Boolean(errors.medicationDetails)}
            className={classes.input}
          >
            <FormLabel className={classes.label}>
              Please state what the medication(s) is/are:
            </FormLabel>

            <Controller
              as={
                <TextField
                  placeholder="Enter detail"
                  fullWidth
                  variant="filled"
                  error={errors.medicationDetails ? true : false}
                  helperText={errors.medicationDetails?.message}
                />
              }
              name="medicationDetails"
              control={control}
              defaultValue=""
              rules={{
                required: "This is required",
              }}
            />
          </FormControl>
        )}

        {/* Covid19 */}
        {/* <FormControl
          error={Boolean(errors.isCovid19Vaccinated)}
          className={classes.input}
        >
          <FormLabel className={classes.label}>
            Have you been vaccinated against Covid-19?
          </FormLabel>
          <Controller
            as={
              <Select variant="filled" defaultValue="" displayEmpty={true}>
                <MenuItem value="">Select an option</MenuItem>
                <MenuItem value="No">No</MenuItem>
                <MenuItem value="First dose">First dose</MenuItem>
                <MenuItem value="First and second dose">
                  First and second dose
                </MenuItem>
                <MenuItem value="First, second and booster">
                  First, second and booster
                </MenuItem>
              </Select>
            }
            name="isCovid19Vaccinated"
            control={control}
            defaultValue=""
            rules={{
              required: "This is required",
            }}
          />
          <FormHelperText>
            {errors.isCovid19Vaccinated && errors.isCovid19Vaccinated.message}
          </FormHelperText>
        </FormControl>

        <FormControl
          error={Boolean(errors.hasContractedCovid19)}
          className={classes.input}
        >
          <FormLabel className={classes.label}>
            In the past 10 days have you been identified as a household contact?
          </FormLabel>
          <Controller
            as={
              <Select variant="filled" defaultValue="" displayEmpty={true}>
                <MenuItem value="">Select an option</MenuItem>
                <MenuItem value="No">No</MenuItem>
                <MenuItem value="Yes">Yes</MenuItem>
              </Select>
            }
            name="hasContractedCovid19"
            control={control}
            defaultValue=""
            rules={{
              required: "This is required",
            }}
          />
          <FormHelperText>
            {errors.hasContractedCovid19 && errors.hasContractedCovid19.message}
          </FormHelperText>
        </FormControl>

        {watch("hasContractedCovid19") === "Yes" && (
          <FormControl
            error={Boolean(errors.isClearedToWork)}
            className={classes.input}
          >
            <FormLabel className={classes.label}>
              Have you been cleared by your GP to work?
            </FormLabel>
            <Controller
              as={
                <Select variant="filled" defaultValue="" displayEmpty={true}>
                  <MenuItem value="">Select an option</MenuItem>
                  <MenuItem value="No">No</MenuItem>
                  <MenuItem value="Yes">Yes</MenuItem>
                </Select>
              }
              name="isClearedToWork"
              control={control}
              defaultValue=""
              rules={{
                required: "This is required",
              }}
            />
            <FormHelperText>
              {errors.isClearedToWork && errors.isClearedToWork.message}
            </FormHelperText>
          </FormControl>
        )}

        {watch("isClearedToWork") === "No" && (
          <p>
            Please follow all government guidelines around working and Covid-19
            at{" "}
            <a
              target="_blank"
              href="https://covid19.govt.nz/business-and-money/businesses/"
            >
              https://covid19.govt.nz/business-and-money/businesses/
            </a>
          </p>
        )}

        <FormControl
          error={Boolean(errors.covidCertificateImage)}
          className={classes.input}
        >
          <FormLabel className={classes.label}>
            Upload your COVID-19 Certificate below.
          </FormLabel>
          <Controller
            control={control}
            name="covidCertificateImage"
            defaultValue=""
            rules={
              {
                // required: hasDriversLicense
              }
            }
            render={() => (
              <div>
                <Dropzone
                  onDrop={handleCovidCertificateDrop}
                  multiple={false}
                  accept={[
                    "image/jpeg",
                    "image/png",
                    "application/pdf",
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                  ]}
                >
                  {({ getRootProps, getInputProps }) => (
                    <Paper
                      variant="outlined"
                      {...getRootProps({
                        className: getCovidCertificateDropzoneClass(),
                      })}
                    >
                      <input
                        {...getInputProps()}
                        name="covidCertificateImage"
                      />
                      <p>Drag 'n' drop files here, or click to select files</p>
                    </Paper>
                  )}
                </Dropzone>
                {uploadedCovidCertificate.length !== 0 && (
                  <List>
                    {uploadedCovidCertificate.map((f: File, index: number) => (
                      <ListItem key={index}>
                        <ListItemIcon>
                          <InsertDriveFile />
                        </ListItemIcon>
                        <ListItemText
                          primary={f.name}
                          secondary={calcSize(f.size)}
                        />
                      </ListItem>
                    ))}
                  </List>
                )}
              </div>
            )}
          />
          <FormHelperText>
            {errors.covidCertificateImage && "This is required"}
          </FormHelperText>
        </FormControl> */}
      </section>

      <section className={classes.section}>
        <h3 style={{ marginBottom: 16 }}>Next of Kin/ Emergency Contact</h3>

        <FormControl
          error={Boolean(errors.emergencyContactName)}
          className={classes.input}
        >
          <FormLabel className={classes.label}>Full Name</FormLabel>

          <Controller
            as={
              <TextField
                placeholder="Enter full name"
                fullWidth
                variant="filled"
                error={errors.emergencyContactName ? true : false}
                helperText={errors.emergencyContactName?.message}
              />
            }
            name="emergencyContactName"
            control={control}
            defaultValue=""
            rules={{
              required: "This is required",
            }}
          />
        </FormControl>

        <FormControl
          error={Boolean(errors.emergencyContactRelationship)}
          className={classes.input}
        >
          <FormLabel className={classes.label}>Relationship</FormLabel>

          <Controller
            as={
              <TextField
                placeholder="Enter relationship"
                fullWidth
                variant="filled"
                error={errors.emergencyContactRelationship ? true : false}
                helperText={errors.emergencyContactRelationship?.message}
              />
            }
            name="emergencyContactRelationship"
            control={control}
            defaultValue=""
            rules={{
              required: "This is required",
            }}
          />
        </FormControl>

        <FormControl
          error={Boolean(errors.emergencyContactPhone)}
          className={classes.input}
        >
          <FormLabel className={classes.label}>Phone</FormLabel>

          <Controller
            as={
              <TextField
                placeholder="Enter phone"
                fullWidth
                variant="filled"
                error={errors.emergencyContactPhone ? true : false}
                helperText={errors.emergencyContactPhone?.message}
              />
            }
            name="emergencyContactPhone"
            control={control}
            defaultValue=""
            rules={{
              required: "This is required",
            }}
          />
        </FormControl>

        <FormControl
          className={classes.input}
          error={Boolean(errors.emergencyContactMobile)}
        >
          <FormLabel className={classes.label}>Mobile</FormLabel>

          <Controller
            render={({ onChange, value }) => (
              <TextField
                placeholder="Enter mobile number"
                fullWidth
                variant="filled"
                size="small"
                value={value}
                onChange={(v) => {
                  onChange(v);
                  clearErrors("emergencyContactMobile");
                }}
                error={errors.emergencyContactMobile ? true : false}
                helperText={errors.emergencyContactMobile?.message}
              />
            )}
            name="emergencyContactMobile"
            control={control}
            defaultValue=""
            rules={{
              required: "This is required",
            }}
          />
        </FormControl>
      </section>
      <div>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleSkip()}
          disabled={isLoading}
        >
          Skip
        </Button>

        <Button
          variant="contained"
          color="primary"
          style={{ marginLeft: 8 }}
          onClick={handleSubmit(handleNext)}
          disabled={isLoading}
        >
          Next
        </Button>
      </div>
    </div>
  );
};
export default AdditionalInformationPart1;
