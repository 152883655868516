import React, { useState, useEffect } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Routes, Route, useNavigate } from "react-router-dom";
import {
  Typography,
  Card,
  CircularProgress,
  Paper,
  Button,
  Grid,
  TextField,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Hidden,
  Divider,
  LinearProgress,
} from "@material-ui/core";
import {
  LocationOn,
  AccountBalanceWallet,
  DateRange,
} from "@material-ui/icons";
import JobDetails from "./JobDetails";
import { API } from "aws-amplify";
import { Candidate } from "../../CandidateTypes";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    jobCard: {
      marginBottom: 48,
      display: "flex",
    },
    jobInfo: {
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
      marginBottom: 8,
    },
    jobInfoIcon: {
      marginRight: 4,
    },
    jobDescription: {
      display: "flex",
      flexGrow: 1,
      margin: 16,
      flexDirection: "column",
      justifyContent: "space-between",
    },
    jobDetailPaper: {
      [theme.breakpoints.up("lg")]: {
        maxWidth: 400,
        minWidth: 400,
      },
      [theme.breakpoints.down("md")]: {
        maxWidth: 350,
        minWidth: 350,
      },
      backgroundImage: "linear-gradient(#95c54b, #259a49)",
    },
    jobDetailPaperMobile: {
      [theme.breakpoints.down("xs")]: {
        // maxWidth: 275,
        minWidth: 275,
      },
      backgroundImage: "linear-gradient(#95c54b, #259a49)",
    },
    search: {
      backgroundColor: "#546e7a",
      padding: 8,
      margin: 0,
      maxWidth: 1000,
    },
    searchField: {
      backgroundColor: "#ffffff",
      width: 220,
    },
    searchSubmit: {
      backgroundColor: "#95c54b",
      width: 220,
      height: 55,
      color: "#ffffff",
      "&:hover": {
        backgroundColor: "#649417",
        color: "#FFF",
      },
    },
    jobsDiv: {
      [theme.breakpoints.up("sm")]: {
        margin: 16,
      },
    },
  })
);

export interface ActiveJobs {
  pk: string;
  sk: string;
  activeJobList: Array<Job>;
  updatedAt: string;
}

export interface Job {
  Reference: string;
  Title: string;
  ShortDescription: string;
  AdDetail: string;
  Consultant: string;
  ContactNumber: string;
  ContactEmail: string;
  CompanyName: string;
  Location: string;
  JobType: string;
  Classification: string;
  Position: string;
  LinkOutUrl: string;
  ApplicationEmail: string;
  SalaryType: string;
  SalaryMin: string;
  SalaryMax: string;
  SalaryCurrency: string;
  ResidentOnly: string;
  timestamp: string;
}

interface JobsProps {
  candidate: Candidate | undefined;
}

export default function Jobs({ candidate }: JobsProps) {
  const classes = useStyles();

  const [jobs, setJobs] = useState<Array<Job>>([]);
  const [filteredJobs, setFilteredJobs] = useState<Array<Job>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [keywordInput, setKeywordInput] = useState<string>("");
  const [classification, setClassification] = useState<string>("");
  const [location, setLocation] = useState<string>("");

  // let { path } = useRouteMatch()

  useEffect(() => {
    setLoading(true);
    fetch("api/jobs")
      .then((response: any) => response.json())
      .then((data: ActiveJobs) => {
        if (data) {
          let list = data.activeJobList.sort((a, b) =>
            parseInt(a.timestamp) > parseInt(b.timestamp) ? -1 : 1
          );
          setJobs(list);
          setFilteredJobs(list);
          setLoading(false);
        }
      })
      .catch((error: any) => {
        console.log("Error: fetching jobs", error);
        setLoading(false);
      });
  }, []);

  const handleSearchSubmit = () => {
    let newJobs: Array<Job> = [];
    if (keywordInput !== "") {
      let keywordFilter = jobs.filter((j: Job) => {
        return (
          j.Position.toLowerCase().includes(keywordInput.toLowerCase()) ||
          j.AdDetail.toLowerCase().includes(keywordInput.toLowerCase()) ||
          j.Title.toLowerCase().includes(keywordInput.toLowerCase()) ||
          j.ShortDescription.toLowerCase().includes(keywordInput.toLowerCase())
        );
      });
      newJobs.push(...keywordFilter);

      if (classification !== "") {
        let classificationFilter = newJobs.filter((j: Job) =>
          j.Classification.includes(classification)
        );
        newJobs = classificationFilter;
      }

      if (location !== "") {
        let locationFilter = newJobs.filter((j: Job) =>
          j.Location.includes(location)
        );
        newJobs = locationFilter;
      }
    } else if (classification !== "") {
      let classificationFilter = jobs.filter((j: Job) =>
        j.Classification.includes(classification)
      );
      newJobs.push(...classificationFilter);

      if (location !== "") {
        let locationFilter = newJobs.filter((j: Job) =>
          j.Location.includes(location)
        );
        newJobs = locationFilter;
      }
    } else if (location !== "") {
      let locationFilter = jobs.filter((j: Job) =>
        j.Location.includes(location)
      );
      newJobs.push(...locationFilter);
    } else {
      newJobs.push(...jobs);
    }

    setFilteredJobs(newJobs);
  };

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Grid container spacing={2} className={classes.search} justify="center">
          <Grid item>
            <TextField
              value={keywordInput}
              variant="outlined"
              placeholder="Enter Keywords"
              InputProps={{ className: classes.searchField }}
              onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
                setKeywordInput(event.target.value as string)
              }
            />
          </Grid>
          <Grid item>
            <TextField
              select
              label={classification === "" ? "Job Classification" : ""}
              value={classification}
              variant="outlined"
              InputProps={{ className: classes.searchField }}
              InputLabelProps={{ shrink: false }}
              onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
                setClassification(event.target.value as string)
              }
            >
              <MenuItem value={""}>
                <em>None</em>
              </MenuItem>
              <MenuItem value={"Trades"}>Trades</MenuItem>
              <MenuItem value={"Logistics, Trans, Supply"}>
                Logistics, Trans, Supply
              </MenuItem>
              <MenuItem value={"Hosp, Travel & Tourism"}>
                Hosp, Travel & Tourism
              </MenuItem>
              <MenuItem value={"Other"}>Other</MenuItem>
              <MenuItem value={"Manufacturing"}>Manufacturing</MenuItem>
            </TextField>
          </Grid>
          <Grid item>
            <TextField
              select
              label={location === "" ? "Location" : ""}
              value={location}
              variant="outlined"
              InputProps={{ className: classes.searchField }}
              InputLabelProps={{ shrink: false }}
              onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
                setLocation(event.target.value as string)
              }
            >
              <MenuItem value={""}>
                <em>None</em>
              </MenuItem>
              <MenuItem value={"Auckland"}>Auckland</MenuItem>
              <MenuItem value={"Wellington"}>Wellington</MenuItem>
              <MenuItem value={"Silverdale"}>Silverdale</MenuItem>
            </TextField>
          </Grid>
          <Grid item>
            <Button
              className={classes.searchSubmit}
              variant="contained"
              onClick={() => handleSearchSubmit()}
            >
              <h5>Search</h5>
            </Button>
          </Grid>
        </Grid>
      </div>
      <div className={classes.jobsDiv}>
        <Typography style={{ marginBottom: 64, marginTop: 32 }} variant="h3">
          Jobs
        </Typography>
        <div>
          {loading ? (
            <div>
              <CircularProgress size={60} />
            </div>
          ) : (
            <div style={{ marginBottom: 110 }}>
              {filteredJobs.length !== 0 &&
                filteredJobs.map((job: Job, i: number) => (
                  <JobCard
                    key={i}
                    job={job}
                    jobs={filteredJobs}
                    index={i}
                    candidate={candidate}
                  />
                ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

interface JobCardProps {
  job: Job;
  jobs: Array<Job>;
  index: number;
  candidate: Candidate | undefined;
}

const JobCard = ({ job, jobs, index, candidate }: JobCardProps) => {
  const classes = useStyles();
  // const history = useHistory()
  const navigate = useNavigate();
  // let { url } = useRouteMatch()

  const [applyLoading, setApplyLoading] = useState<boolean>(false);
  const [openApplyDialog, setOpenApplyDialog] = useState<boolean>(false);
  const [applySuccessful, setApplySuccessful] = useState<boolean>(false);
  const [selectedJob, setSelectedJob] = useState<Job>();

  const applyCandidateForJob = (jobRef: string) => {
    if (candidate) {
      setApplyLoading(true);
      let params = {
        body: {
          username: candidate.username,
          jobRef: jobRef,
          data: candidate,
        },
      };
      API.post("", `/candidates/apply`, params)
        .then(() => {
          setApplySuccessful(true);
          setApplyLoading(false);
          setOpenApplyDialog(true);
        })
        .catch((error: any) => {
          console.log("Error: applying candidate for job", error);
          setApplySuccessful(false);
          setApplyLoading(false);
          setOpenApplyDialog(true);
        });
    } else {
      setApplySuccessful(false);
      setOpenApplyDialog(true);
    }
  };

  return (
    <div>
      <Hidden xsDown>
        <Card className={classes.jobCard} raised>
          <Paper className={classes.jobDetailPaper} elevation={3}>
            <div style={{ margin: 16, color: "white" }}>
              <Typography variant="h5" gutterBottom>
                {job.Title}
              </Typography>
              <div className={classes.jobInfo}>
                <LocationOn className={classes.jobInfoIcon} fontSize="large" />
                <Typography variant="h6">{job.Location}</Typography>
              </div>
              <div className={classes.jobInfo}>
                <AccountBalanceWallet
                  className={classes.jobInfoIcon}
                  fontSize="large"
                />
                <Typography variant="h6">{calcSalary(job)}</Typography>
              </div>
              <div className={classes.jobInfo}>
                <DateRange className={classes.jobInfoIcon} fontSize="large" />
                <Typography variant="h6">
                  {calculateExpiryDate(job.timestamp)}
                </Typography>
              </div>
            </div>
          </Paper>
          <div className={classes.jobDescription}>
            <div>
              <strong>
                {job.Classification} / {job.Position}
              </strong>
              <p>{job.ShortDescription}</p>
            </div>
            <div style={{ display: "flex" }}>
              <div style={{ flexGrow: 1 }}></div>
              <div>
                <Button
                  style={{ marginRight: 16, width: 130 }}
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    navigate(`/portal/jobs/${job.Reference}`, {
                      state: {
                        jobs: jobs,
                        currentJobIdx: index,
                        candidate: candidate,
                      },
                    });
                  }}
                >
                  Read More
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ width: 130 }}
                  onClick={() => {
                    setSelectedJob(job);
                    applyCandidateForJob(job.Reference);
                  }}
                >
                  {applyLoading ? (
                    <CircularProgress size={25} style={{ color: "#ffffff" }} />
                  ) : (
                    <span>Apply Now</span>
                  )}
                </Button>
              </div>
            </div>
          </div>
        </Card>
      </Hidden>
      <Hidden smUp>
        <Card style={{ marginBottom: 48 }} raised>
          <Paper className={classes.jobDetailPaperMobile} elevation={3}>
            <div style={{ padding: 16, color: "white" }}>
              <Typography variant="h5" gutterBottom>
                {job.Title}
              </Typography>
              <div className={classes.jobInfo}>
                <LocationOn className={classes.jobInfoIcon} fontSize="large" />
                <Typography variant="h6">{job.Location}</Typography>
              </div>
              <div className={classes.jobInfo}>
                <AccountBalanceWallet
                  className={classes.jobInfoIcon}
                  fontSize="large"
                />
                <Typography variant="h6">{calcSalary(job)}</Typography>
              </div>
              <div className={classes.jobInfo}>
                <DateRange className={classes.jobInfoIcon} fontSize="large" />
                <Typography variant="h6">
                  {calculateExpiryDate(job.timestamp)}
                </Typography>
              </div>
            </div>
          </Paper>
          <div className={classes.jobDescription}>
            <div>
              <strong>
                {job.Classification} / {job.Position}
              </strong>
              <p>{job.ShortDescription}</p>
            </div>
            <div style={{ display: "flex", marginTop: 16 }}>
              <div style={{ flexGrow: 1 }}></div>
              <div>
                <Button
                  style={{ marginRight: 16, width: 130 }}
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    navigate(`/portal/jobs/${job.Reference}`, {
                      state: {
                        jobs: jobs,
                        currentJobIdx: index,
                        candidate: candidate,
                      },
                    });
                  }}
                >
                  Read More
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ width: 130 }}
                  onClick={() => {
                    setSelectedJob(job);
                    applyCandidateForJob(job.Reference);
                  }}
                >
                  {applyLoading ? (
                    <CircularProgress size={25} style={{ color: "#ffffff" }} />
                  ) : (
                    <span>Apply Now</span>
                  )}
                </Button>
              </div>
            </div>
          </div>
        </Card>
      </Hidden>

      <ApplyDialog
        open={openApplyDialog}
        handleDialog={setOpenApplyDialog}
        success={applySuccessful}
        job={selectedJob}
      />
    </div>
  );
};

interface ApplyDialogProps {
  open: boolean;
  handleDialog: (open: boolean) => void;
  success: boolean;
  job: Job | undefined;
}

export const ApplyDialog = ({
  open,
  handleDialog,
  success,
  job,
}: ApplyDialogProps) => {
  return (
    <Dialog open={open} onClose={() => handleDialog(false)}>
      <DialogTitle>Apply</DialogTitle>
      <DialogContent>
        {job && (
          <div>
            {success ? (
              <p>You have successfully applied for the {job.Title} position.</p>
            ) : (
              <p>
                An error occurred. We were unable to apply you for the{" "}
                {job.Title} position.
              </p>
            )}
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleDialog(false)}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const calcSalary = (job: Job): string => {
  if (job.SalaryMin === "" || job.SalaryMin === "0") {
    return `Not Listed`;
  } else if (job.SalaryMax === "" || job.SalaryMax === "0") {
    return `Not Listed`;
  } else if (parseInt(job.SalaryMin) === parseInt(job.SalaryMax)) {
    if (job.SalaryType === "annum") {
      return `$${job.SalaryMin}/p.a.`;
    }
    return `$${job.SalaryMin}/${job.SalaryType}`;
  }

  if (job.SalaryType === "annum") {
    return `$${job.SalaryMin}-${job.SalaryMax}/p.a.`;
  }
  return `$${job.SalaryMin}-${job.SalaryMax}/${job.SalaryType}`;
};

export const calculateExpiryDate = (timestamp: string): string => {
  let timeInSecs = parseInt(timestamp);
  let date = new Date(0);
  date.setUTCSeconds(timeInSecs);
  let expiryDate = new Date(date);
  expiryDate.setDate(expiryDate.getDate() + 14);
  return expiryDate.toDateString();
};

export const openInNewTab = (url: string) => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};
