import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Home from "./routes/Home";
import IndustrialTest from "./routes/actions/IndustrialTest";
import JobPlacement from "./routes/job-placement/JobPlacement";
import Jobs from "./routes/jobs/Jobs";
import Profile from "./routes/profile/Profile";
import DataPrivacy from "./routes/DataPrivacy";
import RegistrationRoute from "./routes/registration/RegistrationRoute";
import { MyAuth } from "./hooks/useAuth";
import PortalRoute from "./components/PortalRoute";
import RegistrationProcess from "./routes/registration/RegistrationProcess";
import Ird from "./routes/actions/Ird";
import BankAccountDetail from "./routes/actions/BankAccountDetail";
import EmploymentContract from "./routes/actions/FixedAgreement";
import KiwiSaver from "./routes/actions/KiwiSaver";
import FixedAgreement from "./routes/actions/FixedAgreement";
import CasualAgreement from "./routes/actions/CasualAgreement";
import ContractAgreement from "./routes/actions/ContractAgreement";
import KiwiSaverOptOut from "./routes/actions/KiwiSaverOptOut";
import JobDetails from "./routes/jobs/JobDetails";

interface Props {
  auth: MyAuth;
}

export default function Portal({ auth }: Props) {
  // let { path } = useRouteMatch();

  return (
    <Routes>
      <Route
        path={`/data-privacy`}
        element={<DataPrivacy candidate={auth.candidate} />}
      />
      {/* <Route path={`/job-placement`} element={<JobPlacement candidate={auth.candidate} />}/> */}
      <Route
        path={`/registration-process/`}
        element={<Navigate to={"/portal/profile"} replace />}
      />
      <Route
        path={`/registration-process/:step`}
        element={<RegistrationProcess candidate={auth.candidate} />}
      />
      <Route path={`/jobs/`} element={<Jobs candidate={auth.candidate} />} />
      <Route
        path={`/jobs/:jobRef`}
        element={<JobDetails candidate={auth.candidate} />}
      />
      <Route
        path={`/profile`}
        element={<Profile candidate={auth.candidate} />}
      />
      <Route path={`/actions`}>
        <Route path={`ird`} element={<Ird candidate={auth.candidate} />} />
        <Route
          path={`bank-detail`}
          element={<BankAccountDetail candidate={auth.candidate} />}
        />
        <Route
          path={`kiwi-saver`}
          element={<KiwiSaver candidate={auth.candidate} />}
        />
        <Route
          path={`fixed-agreement`}
          element={<FixedAgreement candidate={auth.candidate} />}
        />
        <Route
          path={`casual-agreement`}
          element={<CasualAgreement candidate={auth.candidate} />}
        />
        <Route
          path={`contract-agreement`}
          element={<ContractAgreement candidate={auth.candidate} />}
        />
        <Route
          path={`industrial-items`}
          element={<IndustrialTest candidate={auth.candidate} />}
        />
        <Route
          path={`kiwi-saver-opt-out`}
          element={<KiwiSaverOptOut candidate={auth.candidate} />}
        />
      </Route>
      <Route path={`/`} element={<Home auth={auth} />} />
    </Routes>
  );
}
