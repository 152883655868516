import { useState, useEffect, useMemo } from 'react'
import Amplify, { Auth, API } from 'aws-amplify'
import { Candidate } from '../CandidateTypes';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth/lib/types';

interface User {
    user: any | null;
    isSignedIn: boolean;
}

interface PortalUser {
    candidate: Candidate | undefined;
    isCandidateSignedIn: boolean;
}

export interface MyAuth extends User, PortalUser {
    loading: boolean;
    signIn: (username: string, password: string) => Promise<void | never>
    signOut: () => void
    signUp: (signUpObj: any) => Promise<any>;
    confirmSignUp: (confirmObj: any) => Promise<any>;
    resendSignUp: (username: string) => Promise<any>;
    forgotPassword: (username: string) => Promise<any>;
    changePassword: (oldPassword: string, newPassword: string) => Promise<any>;
    forgotPasswordSubmit: (username: string, code: string, newPassword: string) => Promise<any>;
    setCandidate: (candidate: Candidate) => void;
    setUser: (user: any | null) => void;
    socialSignIn: (provider: CognitoHostedUIIdentityProvider, from: string) => Promise<any>
}

const useAuth = (): MyAuth => {
    const config = {
        Auth: {
            identityPoolId: 'ap-southeast-2:fe522c4d-e874-4406-bb15-b628a63b1161', //REQUIRED - Amazon Cognito Identity Pool ID
            region: 'ap-southeast-2', // REQUIRED - Amazon Cognito Region
            userPoolId: 'ap-southeast-2_pTUBaJkul', //OPTIONAL - Amazon Cognito User Pool ID
            userPoolWebClientId: '39v4qp5psk2dsnc72tum60s03m', //OPTIONAL - Amazon Cognito Web Client ID
            // OPTIONAL - Hosted UI configuration
            oauth: {
                domain: 'lowie-portal.auth.ap-southeast-2.amazoncognito.com',
                scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
                redirectSignIn: `${window.location.protocol}//${window.location.host}/`,
                redirectSignOut: `${window.location.protocol}//${window.location.host}/`,
                responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
            },
            federatedTarget: "COGNITO_USER_POOLS"
        },
        API: {
            endpoints: [
                {
                    name: "",
                    endpoint: "/api",
                    // region: 'ap-southeast-2',
                    custom_header: async () => {
                        return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
                    }
                }
            ]
        },
        Storage: {
            AWSS3: {
                bucket: 'lr-onboarding-candidate-files',
                region: 'ap-southeast-2',
            }
        }
    }

    const [state, setState] = useState<User>({
        user: null,
        isSignedIn: false
    })

    const [candidate, setCandidateState] = useState<PortalUser>({
        candidate: undefined,
        isCandidateSignedIn: false
    })

    const [loading, setLoading] = useState<boolean>(true)

    const { auth, api } = useMemo(() => {
        Amplify.configure(config)
        return { auth: Auth, api: API }
    }, [])

    useEffect(() => {

        setLoading(true)

        auth.currentAuthenticatedUser()
            .then((user) => {
                // console.log("USER", user)
                setState({ user, isSignedIn: true })

                api.get("", `/candidates/${user.username}`, {})
                    .then((response: Candidate) => {
                        // console.log(response)
                        if (response) {

                            setCandidateState({ candidate: response, isCandidateSignedIn: true })
                        }
                        setLoading(false)
                    })
                    .catch((error: any) => {
                        console.log("ERROR", error)
                        setLoading(false)
                    })
            })
            .catch((error: any) => {
                console.log("ERROR", error)
                setLoading(false)
            })
    }, [])

    const socialSignIn = (provider: CognitoHostedUIIdentityProvider, from: string) => auth.federatedSignIn({ provider: provider, customState: from })


    const signIn = async (username: string, password: string): Promise<void | never> => {

        try {
            setLoading(true)

            let user = await auth.signIn(username, password)
            // console.log(user)
            if (user) {
                setState({ user, isSignedIn: true })

                api.get("", `/candidates/${user.username}`, {})
                    .then((response: Candidate) => {
                        // console.log(response)
                        if (response) {
                            setCandidateState({ candidate: response, isCandidateSignedIn: true })
                        }
                        setLoading(false)
                    })
                    .catch((error: any) => {
                        console.log("ERROR", error)
                        setLoading(false)
                    })
            }
        } catch (e) {
            console.log("ERROR", e)
            setLoading(false)
            if (e.code === "UserNotConfirmedException") {
                throw new Error("UserNotConfirmedException");
            } else if (e.code === "NotAuthorizedException") {
                throw new Error(e.message)
            }
        }
    }

    const signOut = () => {
        auth.signOut().then(() => {
            setState({ user: null, isSignedIn: false })
            setCandidateState({ candidate: undefined, isCandidateSignedIn: false })
        })
            .catch((error: any) => {
                console.log("ERROR SIGNING OUT", error)
            })
    }

    const signUp = (signUpObj: any): Promise<any> => {
        const { username, password, email, given_name, family_name, phone_number } = signUpObj
        return auth.signUp({
            username,
            password,
            attributes: {
                email,
                given_name,
                family_name,
                phone_number
            }
        })
    }

    const confirmSignUp = (confirmObj: any): Promise<any> => {
        const { username, code } = confirmObj
        return auth.confirmSignUp(username, code);
    }

    const resendSignUp = (username: string) => {
        return auth.resendSignUp(username)
    }

    const forgotPassword = (username: string) => {
        return auth.forgotPassword(username)
    }

    const forgotPasswordSubmit = (username: string, code: string, newPassword: string) => {
        return auth.forgotPasswordSubmit(username, code, newPassword)
    }

    const changePassword = async (oldPassword: string, newPassword: string): Promise<any> => {
        let user = await auth.currentAuthenticatedUser()
        return auth.changePassword(user, oldPassword, newPassword)
    }

    const setCandidate = (candidate: Candidate) => setCandidateState({ candidate: candidate, isCandidateSignedIn: true })

    const setUser = (user: any | undefined) => setState({ user: user, isSignedIn: true })

    return {
        ...state,
        ...candidate,
        loading,
        signIn,
        signOut,
        signUp,
        confirmSignUp,
        resendSignUp,
        forgotPassword,
        forgotPasswordSubmit,
        changePassword,
        setCandidate,
        setUser,
        socialSignIn
    }
}

export default useAuth