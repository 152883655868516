import React, { useState, useEffect } from 'react'
import { MyAuth } from '../../hooks/useAuth';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  CircularProgress, Button, Card, FormControl,
  FormLabel, TextField
} from "@material-ui/core";
import {
  useNavigate,
  useLocation,
} from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      marginBottom: theme.spacing(3),
      width: "100%"
    },
    label: {
        marginBottom: 6
    },
    error: {
      '&.MuiFormHelperText-contained': {
        marginLeft: 0
      }
    },
    submit: {
      width: 100
    },
  }),
);

interface Props {
    auth: MyAuth
}

interface LocationProps {
    userEmail: string
}

export default function VerifyUser({ auth }: Props) {

    const classes = useStyles()
    const [confirmationCode, setConfirmationCode] = useState<string>()
    const [confirmSignUpLoading, setConfirmSignUpLoading] = useState<boolean>(false)
    const [userEmail, setUserEmail] = useState<string>()
    const [confirmationCodeError, setConfirmationCodeError] = useState<string>()
    const [error, setError] = useState<string>()

    const navigate = useNavigate()
    const location = useLocation<LocationProps>()

    useEffect(() => {
        if(location.state) {
            if(location.state.userEmail) {
                setUserEmail(location.state.userEmail)
            }
        } else {
            navigate("/")
        }
    }, [location.state])

    useEffect(() => {
        if(userEmail) {
            sendConfirmationCode(userEmail)
        }
    }, [userEmail])

    const sendConfirmationCode = (email: string) => {
        setConfirmationCodeError(undefined)
        setError(undefined)
        
        auth.resendSignUp(email)
        .then(() => {})
        .catch((error: any) => {
            setConfirmationCodeError(error.message)
        })
    }

    const verifyUser = () => {
        setConfirmationCodeError(undefined)
        setError(undefined)
        
        if(confirmationCode !== "") {
          setConfirmSignUpLoading(true)
          let params = {
              username: userEmail,
              code: confirmationCode
          }
          auth.confirmSignUp(params)
          .then((response) => {
              console.log(response)
              navigate("/")
          })
          .catch((error: any) => {
              console.log("ERROR: Verifying sign up", error)
              setConfirmSignUpLoading(false)
              setError(error.message)
          })
        } else {
            setError("Code required")
        }
    }

    return (
        <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh"
        }}>
            <Card style={{padding: 16, width: 650}}>
                <h3 style={{marginBottom: 32}}>Confirm Registration</h3>
                
                <p style={{marginBottom: 16}}>
                    An email has been sent to you with a verification code.
                </p>

                {confirmationCodeError &&
                    <div style={{color: "red", marginBottom: 16}}>
                        {confirmationCodeError}
                    </div>
                }
                <FormControl 
                    className={classes.input}>
        
                    <FormLabel className={classes.label}>Verification code</FormLabel>

                    <TextField
                        placeholder="Enter code"
                        fullWidth
                        variant="filled"
                        size="small"
                        value={confirmationCode}
                        onChange={(event) => {
                            let newVal = event.target.value
                            setConfirmationCode(newVal)
                        }}
                        FormHelperTextProps={{className: classes.error}}/>
                </FormControl>

                <a href="#" onClick={(event) => {
                    event.preventDefault()
                    console.log(userEmail)
                    if(userEmail) {
                        sendConfirmationCode(userEmail)
                    }
                }}>
                    Resend Code
                </a>

                <div style={{display: "flex", marginTop: 16}}>
                    <div style={{flexGrow: 1}}>
                        {error &&
                            <span style={{color: "red", marginRight: 8}}>{error}</span>
                        }
                    </div>
                    {confirmSignUpLoading ?
                        <Button className={classes.submit} variant="contained" color="primary">
                            <CircularProgress size={25} style={{color: "white"}}/>
                        </Button>
                    :
                        <Button className={classes.submit} variant="contained" color="primary" onClick={() => verifyUser()}>
                            Confirm
                        </Button>
                    }
                </div>
            </Card>
        </div>
    )
}
