import React, { useState, useEffect } from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Button,
  FormControl,
  FormLabel,
  FormHelperText,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import Dropzone from "react-dropzone";
import { InsertDriveFile } from "@material-ui/icons";
import { API, Storage } from "aws-amplify";
import { Candidate } from "../../CandidateTypes";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    input: {
      marginBottom: theme.spacing(3),
      width: "100%",
    },
    label: {
      marginBottom: 6,
    },
    header: {
      display: "flex",
      marginBottom: 16,
    },
    title: {
      flexGrow: 1,
    },
    uploadCard: {
      marginTop: 8,
      marginBottom: 24,
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    dropzone: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingTop: 97,
      paddingBottom: 97,
      paddingLeft: 20,
      paddingRight: 20,
      borderWidth: 2,
      borderRadius: 2,
      borderColor: "#bdbdbd",
      borderStyle: "dashed",
      backgroundColor: "#eeeeee",
      color: "#757575",
      outline: "none",
      transition: "border .24s ease-in-out",
    },
    error: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingTop: 97,
      paddingBottom: 97,
      paddingLeft: 20,
      paddingRight: 20,
      borderWidth: 2,
      borderRadius: 2,
      borderColor: "red",
      borderStyle: "dashed",
      backgroundColor: "#eeeeee",
      color: "#757575",
      outline: "none",
      transition: "border .24s ease-in-out",
    },
    disabled: {
      opacity: 0.4,
    },
  })
);

type RightToWorkForm = {
  nzCitizen: string;
  entitledToWork: string;
  passport: string;
  workVisa: string;
  birthCert: string;
  documentType: string;
};

interface Props {
  candidate?: Candidate;
  activeStep: number;
  incrementActiveStep: () => void;
}

export default function RightToWork({
  candidate,
  activeStep,
  incrementActiveStep,
}: Props) {
  const classes = useStyles();

  const { handleSubmit, watch, control, errors, setValue, clearErrors, reset } =
    useForm<RightToWorkForm>({
      defaultValues: {
        nzCitizen: "",
        entitledToWork: "",
        documentType: "",
        passport: "",
        workVisa: "",
        birthCert: "",
      },
      shouldUnregister: false,
    });

  const [isLoading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    let jsonForm = localStorage.getItem("rightToWorkForm");
    if (jsonForm) {
      let form: RightToWorkForm = JSON.parse(jsonForm);
      reset(form);
    }
  }, []);

  const nzCitizen: string = watch("nzCitizen");
  const entitledToWork: string = watch("entitledToWork");
  const [uploadedPassport, setPassport] = useState<Array<File>>([]);
  const [uploadedWorkVisa, setWorkVisa] = useState<Array<File>>([]);
  const [uploadedBirthCert, setBirthCert] = useState<Array<File>>([]);
  const [showErrorDialog, setShowErrorDialog] = useState<boolean>(false);

  const handleNext = (data: RightToWorkForm) => {
    if (data.entitledToWork === "No") {
      setShowErrorDialog(true);
    } else {
      setLoading(true);

      localStorage.setItem("rightToWorkForm", JSON.stringify(data));

      const params = {
        body: {
          data: data,
          step: activeStep,
        },
      };

      API.post("", `/candidates/${candidate?.username}/registration`, params)
        .then(() => {
          setLoading(false);
          incrementActiveStep();
        })
        .catch((error: any) => {
          console.log("ERROR", error);
          setLoading(false);
        });
    }
  };

  const handleSkip = () => {
    setLoading(true);

    const params = {
      body: {
        id: candidate?.username,
        step: activeStep,
      },
    };

    API.post("", `/candidates/registration/skip`, params)
      .then(() => {
        setLoading(false);
        incrementActiveStep();
      })
      .catch((error: any) => {
        console.log("ERROR: ", error);
        setLoading(false);
      });
  };

  const handlePassportDrop = (files: Array<File>) => {
    var objKey = candidate?.username + "_PASSPORT_" + files[0].name;

    console.log("FILE UPLOAD", objKey);

    setLoading(true);
    Storage.put(objKey, files[0])
      .then(() => {
        Storage.get(objKey, { level: "public", download: false })
          .then(() => {
            setValue("passport", objKey);
            clearErrors("passport");
            setPassport(files);
          })
          .catch((error: any) => {
            console.log("ERROR fetching from s3", error);
          });
      })
      .catch((error: any) => {
        console.log("ERROR uploading to s3", error);
      });
    setLoading(false);
  };

  const handleWorkVisaDrop = (files: any) => {
    var objKey = candidate?.username + "_WORKVISA_" + files[0].name;

    console.log("FILE UPLOAD", objKey);

    setLoading(true);
    Storage.put(objKey, files[0])
      .then(() => {
        Storage.get(objKey, { level: "public", download: false })
          .then(() => {
            setValue("workVisa", objKey);
            clearErrors("workVisa");
            setWorkVisa(files);
          })
          .catch((error: any) => {
            console.log("ERROR fetching from s3", error);
          });
      })
      .catch((error: any) => {
        console.log("ERROR uploading to s3", error);
      });
    setLoading(false);
  };

  const handleBirthCertDrop = (files: any) => {
    var objKey = candidate?.username + "_BIRTHCERT_" + files[0].name;

    console.log("FILE UPLOAD", objKey);

    setLoading(true);
    Storage.put(objKey, files[0])
      .then(() => {
        Storage.get(objKey, { level: "public", download: false })
          .then(() => {
            setValue("birthCert", objKey);
            clearErrors("birthCert");
            setBirthCert(files);
          })
          .catch((error: any) => {
            console.log("ERROR fetching from s3", error);
          });
      })
      .catch((error: any) => {
        console.log("ERROR uploading to s3", error);
      });
    setLoading(false);
  };

  function getPassportDropzoneClass() {
    if (errors.passport) {
      return classes.error;
    } else {
      return classes.dropzone;
    }
  }

  function getWorkVisaDropzoneClass() {
    if (errors.workVisa) {
      return classes.error;
    } else {
      return classes.dropzone;
    }
  }

  function getBirthCertDropzoneClass() {
    if (errors.birthCert) {
      return classes.error;
    } else {
      return classes.dropzone;
    }
  }

  function calcSize(fileSize: number): string {
    if (fileSize < 1000000) {
      return `${Math.round(fileSize / Math.pow(10, 3))} KB`;
    }
    return `${(fileSize / Math.pow(10, 6)).toFixed(1)} MB`;
  }

  return (
    <div>
      <div className={classes.header}>
        <h2>Right To Work</h2>
      </div>
      <p style={{ marginBottom: 24 }}>Enter your right to work details.</p>

      <FormControl error={Boolean(errors.nzCitizen)} className={classes.input}>
        <FormLabel className={classes.label}>
          {" "}
          Are you a New Zealand citizen or permanent resident
        </FormLabel>

        <Controller
          render={({ onChange, value }) => (
            <Select
              variant="filled"
              displayEmpty={true}
              defaultValue=""
              value={value}
              onChange={(event) => {
                let val = event.target.value as string;
                onChange(val);
                if (val === "Yes") {
                  setValue("entitledToWork", "");
                }
                clearErrors(["birthCert", "passport", "workVisa"]);
              }}
            >
              <MenuItem value="">Select an option</MenuItem>
              <MenuItem value="Yes">Yes</MenuItem>
              <MenuItem value="No">No</MenuItem>
            </Select>
          )}
          name="nzCitizen"
          control={control}
          defaultValue={nzCitizen}
          rules={{
            required: "This is required",
          }}
        />
        <FormHelperText>
          {errors.nzCitizen && errors.nzCitizen.message}
        </FormHelperText>
      </FormControl>

      {nzCitizen === "No" && (
        <div>
          <FormControl
            error={Boolean(errors.entitledToWork)}
            className={classes.input}
          >
            <FormLabel className={classes.label}>
              {" "}
              Are you legally entitled to work in New Zealand
            </FormLabel>

            <Controller
              as={
                <Select
                  variant="filled"
                  displayEmpty={true}
                  defaultValue={entitledToWork}
                >
                  <MenuItem value="">Select an option</MenuItem>
                  <MenuItem value="Yes">Yes</MenuItem>
                  <MenuItem value="No">No</MenuItem>
                </Select>
              }
              name="entitledToWork"
              control={control}
              defaultValue={entitledToWork}
              rules={{
                required: "This is required",
              }}
            />
            <FormHelperText>
              {errors.entitledToWork && errors.entitledToWork.message}
            </FormHelperText>
          </FormControl>
        </div>
      )}
      {(nzCitizen === "Yes" || entitledToWork === "Yes") && (
        <div>
          <h3 style={{ marginBottom: 16 }}>Documents</h3>

          {nzCitizen === "Yes" && (
            <FormControl
              error={Boolean(errors.documentType)}
              className={classes.input}
            >
              <FormLabel className={classes.label}>
                {" "}
                Select document to upload
              </FormLabel>

              <Controller
                render={({ onChange, value }) => (
                  <Select
                    variant="filled"
                    displayEmpty={true}
                    defaultValue=""
                    value={value}
                    onChange={(event) => {
                      let val = event.target.value as string;
                      onChange(val);
                      clearErrors(["birthCert", "passport", "workVisa"]);
                    }}
                  >
                    <MenuItem value="">Select an option</MenuItem>
                    <MenuItem value="Passport">Passport</MenuItem>
                    <MenuItem value="Birth Certificate">
                      Birth Certificate
                    </MenuItem>
                  </Select>
                )}
                name="documentType"
                control={control}
                defaultValue=""
                rules={{
                  required: "This is required",
                }}
              />
              <FormHelperText>
                {errors.documentType && errors.documentType.message}
              </FormHelperText>
            </FormControl>
          )}

          {(watch("documentType") === "Passport" ||
            entitledToWork === "Yes") && (
            <>
              <h6 style={{ marginTop: 8, marginBottom: 8 }}>Passport</h6>

              <Grid item className={classes.uploadCard} sm={12} lg={6}>
                <FormControl
                  error={Boolean(errors.passport)}
                  className={classes.input}
                >
                  <FormLabel className={classes.label}>
                    Upload Passport
                  </FormLabel>
                  <Controller
                    control={control}
                    name="passport"
                    defaultValue=""
                    rules={{
                      required: "This is required",
                    }}
                    render={() => (
                      <div>
                        <Dropzone
                          onDrop={handlePassportDrop}
                          multiple={false}
                          accept={[
                            "image/jpeg",
                            "image/png",
                            "application/pdf",
                            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                          ]}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <Paper
                              variant="outlined"
                              {...getRootProps({
                                className: getPassportDropzoneClass(),
                              })}
                            >
                              <input {...getInputProps()} name="passport" />
                              <p>
                                Drag 'n' drop files here, or click to select
                                files
                              </p>
                            </Paper>
                          )}
                        </Dropzone>
                        {uploadedPassport.length !== 0 && (
                          <List>
                            {uploadedPassport.map((f: File, index: number) => (
                              <ListItem key={index}>
                                <ListItemIcon>
                                  <InsertDriveFile />
                                </ListItemIcon>
                                <ListItemText
                                  primary={f.name}
                                  secondary={calcSize(f.size)}
                                />
                              </ListItem>
                            ))}
                          </List>
                        )}
                      </div>
                    )}
                  />
                  <FormHelperText>
                    {errors.passport && "This is required"}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </>
          )}
        </div>
      )}

      {nzCitizen === "Yes" && watch("documentType") === "Birth Certificate" && (
        <div>
          <h6 style={{ marginTop: 8, marginBottom: 8 }}>Birth Certificate</h6>

          <Grid item className={classes.uploadCard} sm={12} lg={6}>
            <FormControl
              error={Boolean(errors.birthCert)}
              className={classes.input}
            >
              <FormLabel className={classes.label}>
                Upload Birth Certificate
              </FormLabel>
              <Controller
                control={control}
                name="birthCert"
                defaultValue=""
                rules={{
                  required: "This is required",
                }}
                render={() => (
                  <div>
                    <Dropzone
                      onDrop={handleBirthCertDrop}
                      multiple={false}
                      accept={[
                        "image/jpeg",
                        "image/png",
                        "application/pdf",
                        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                      ]}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <Paper
                          variant="outlined"
                          {...getRootProps({
                            className: getBirthCertDropzoneClass(),
                          })}
                        >
                          <input {...getInputProps()} name="birthCert" />
                          <p>
                            Drag 'n' drop files here, or click to select files
                          </p>
                        </Paper>
                      )}
                    </Dropzone>
                    {uploadedBirthCert.length !== 0 && (
                      <List>
                        {uploadedBirthCert.map((f: File, index: number) => (
                          <ListItem key={index}>
                            <ListItemIcon>
                              <InsertDriveFile />
                            </ListItemIcon>
                            <ListItemText
                              primary={f.name}
                              secondary={calcSize(f.size)}
                            />
                          </ListItem>
                        ))}
                      </List>
                    )}
                  </div>
                )}
              />
              <FormHelperText>
                {errors.birthCert && "This is required"}
              </FormHelperText>
            </FormControl>
          </Grid>
        </div>
      )}

      {entitledToWork === "Yes" && (
        <div>
          <h6 style={{ marginTop: 8, marginBottom: 8 }}>Work Visa</h6>

          <Grid item className={classes.uploadCard} sm={12} lg={6}>
            <FormControl
              error={Boolean(errors.workVisa)}
              className={classes.input}
            >
              <FormLabel className={classes.label}>Upload Work Visa</FormLabel>
              <Controller
                control={control}
                name="workVisa"
                defaultValue=""
                rules={{
                  required: "This is required",
                }}
                render={() => (
                  <div>
                    <Dropzone
                      onDrop={handleWorkVisaDrop}
                      multiple={false}
                      accept={[
                        "image/jpeg",
                        "image/png",
                        "application/pdf",
                        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                      ]}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <Paper
                          variant="outlined"
                          {...getRootProps({
                            className: getWorkVisaDropzoneClass(),
                          })}
                        >
                          <input {...getInputProps()} name="workVisa" />
                          <p>
                            Drag 'n' drop files here, or click to select files
                          </p>
                        </Paper>
                      )}
                    </Dropzone>
                    {uploadedWorkVisa.length !== 0 && (
                      <List>
                        {uploadedWorkVisa.map((f: File, index: number) => (
                          <ListItem key={index}>
                            <ListItemIcon>
                              <InsertDriveFile />
                            </ListItemIcon>
                            <ListItemText
                              primary={f.name}
                              secondary={calcSize(f.size)}
                            />
                          </ListItem>
                        ))}
                      </List>
                    )}
                  </div>
                )}
              />
              <FormHelperText>
                {errors.workVisa && "This is required"}
              </FormHelperText>
            </FormControl>
          </Grid>
        </div>
      )}

      <div>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleSkip()}
          disabled={isLoading}
        >
          Skip
        </Button>
        <Button
          variant="contained"
          color="primary"
          style={{ marginLeft: 8 }}
          onClick={handleSubmit(handleNext)}
          disabled={isLoading}
        >
          Next
        </Button>
      </div>

      <Dialog
        open={showErrorDialog}
        onClose={() => setShowErrorDialog(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Error</DialogTitle>
        <DialogContent>
          <DialogContentText>
            We are unable to proceed with your application, please contact Lowie
            for details.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setShowErrorDialog(false)}
            color="primary"
            variant="contained"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
